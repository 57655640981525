import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PairsImpurePipe} from './pipes/pairs-impure.pipe';
import {NgPipesModule, PairsPipe} from 'ngx-pipes';
import {LoaderComponent} from './components/loader/loader.component';
import {ValidateDirective} from './directives/validate.directive';
import {OfferExpirationComponent} from './components/offer-expiration/offer-expiration.component';
import {LocalStorageService} from './services/local-storage.service';
import {AirIconsInfoComponent} from './components/air-icons-info/air-icons-info.component';
import {GetMaterialIconsPipe} from './pipes/get-material-icons.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {JoinArrayPipe} from './pipes/join-array.pipe';
import {ParseDurationPipe} from './pipes/parse-flight-duration.pipe';
import {VirtualScrollSelectComponent} from './components/virtual-scroll-select/virtual-scroll-select.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {GetLogoPathPipe} from './pipes/get-logo-path.pipe';
import {ExcludePassengersPipe} from './pipes/exclude-passengers.pipe';
import {MomentDatePipe} from './pipes/moment-date.pipe';
import {DestinationChooseItemComponent} from './components/destination-choose-item/destination-choose-item.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShowTaxesInfoComponent} from './components/show-taxes-info/show-taxes-info.component';
import {GetKeyByValueFormObjPipe} from './pipes/getKeyByValueFormObj.pipe';
import {IsShowGridColumnCheckboxPipe} from './pipes/isShowGridColumnCheckbox.pipe';
import {ContainItemInArrayPipe} from './pipes/containItemInArray.pipe';
import {IsInstanceOfObjectPipe} from './pipes/is-instance-of-object.pipe';
import {PadStartPipe} from './pipes/padStart.pipe';
import {SplitPipe} from './pipes/split.pipe';
import {PaymentFormComponent} from '../order/payment-form/payment-form.component';
import {OfferPriceViewComponent} from './components/offer-price-view/offer-price-view.component';
import {SeatMapComponent} from '../order/seat-map/seat-map.component';
import {MultiSelectComponent} from './components/multi-select/multi-select.component';
import {IsExistItemInArrayPipe} from './pipes/is-exist-item-in-array.pipe';
import {GroupedByFlightsPipe} from './pipes/groupedByFlights.pipe';
import {InputDropdownComponent} from './components/input-dropdown/input-dropdown.component';
import {CustomSelectComponent} from './components/custom-select/custom-select.component';
import {SearchUmbrellaTravellersComponent} from './components/search-umbrella-travellers/search-umbrella-travellers.component';
import {UmbrellaBarComponent} from './components/umbrella-bar/umbrella-bar.component';
import {TimeRangePipe} from './pipes/time-range.pipe';
import {ConnectionListViewComponent} from './components/connection-list-view/connection-list-view.component';
import {Ng5OptionPipe} from './pipes/ng5-option.pipe';
import {GraphRangeDirective} from './directives/graph-range.directive';
import {ServicePerPsgAndSegmentPipe} from './pipes/service-per-psg-and-segment.pipe';
import {TooltipDirective} from './directives/tooltip.directive';
import {GetSegmentRouteByIDPipe} from './pipes/getSegmentRouteByID.pipe';
import {GetPassengerNameByRefPipe} from './pipes/getPassengerNameByRef.pipe';
import {CountdownComponent} from './components/countdown/countdown.component';
import {ReplacePipe} from './pipes/replace.pipe';
import {MainFlightSegmentComponent} from './components/main-flight-segment/main-flight-segment.component';
import {NotificationComponent} from './components/notification/notification.component';
import {FormatFlightNumberPipe} from './pipes/format-flight-number.pipe';
import {DateRangePipe} from './pipes/date-range.pipe';
import {ShowPaxBreakdownInfoComponent} from './components/show-pax-breakdown-info/show-pax-breakdown-info.component';
import {ProfilesBarComponent} from './components/profiles-bar/profiles-bar.component';
import {SearchProfilesComponent} from './components/search-profiles/search-profiles.component';
import {ServiceListPreModalComponent} from '../service-list-pre-modal/service-list-pre-modal.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {OfferGridComponent} from './components/offer-grid/offer-grid.component';
import {AirlineImgComponent} from './components/airline-img/airline-img.component';
import {ShowNumberOfItem} from './pipes/show-number-of-item';
import {ShowProcessingComponent} from './components/show-processing/show-processing.component';
import {DiscountsFormComponent} from './components/discounts-form/discounts-form.component';
import {FrequentFlyerNumberComponent} from './components/frequent-flyer-number/frequent-flyer-number.component';
import {BaggageComponent} from './components/baggage/baggage.component';
import {CounterComponent} from './components/counter/counter.component';
import {HtmlToStringParserPipe} from './pipes/html-to-string-parser';
import {FareTypeComponent} from './components/fare-type/fare-type.component';
import {OrderStatusComponent} from './components/order-status/order-status.component';
import {OptionsComponent} from './components/options/options.component';
import {FilterOfferByCabinClassPipe} from './pipes/filter-offer-by-cabin-class.pipe';
import {FilterSimpleOfferPipe} from './pipes/filter-simple-offer.pipe';
import {FareRulesCapacityInfoComponent} from './components/fare-rules-capacity-info/fare-rules-capacity-info.component';
import {FareRulesFilterComponent} from './components/fare-rules-filter/fare-rules-filter.component';
import {FilterLoaderComponent} from './components/filter-loader/filter-loader.component';
import {RemoveInfantTravelersPipe} from './pipes/remove-infant-travelers.pipe';
import {BaggageAllowanceInfoComponent} from './components/baggage-allowance-info/baggage-allowance-info.component';
import {DisclosuresComponent} from './components/disclosures/disclosures.component';
import {DisclosureIconComponent} from './components/disclosure-icon/disclosure-icon.component';
import {DisclosureTableComponent} from './components/disclosure-table/disclosure-table.component';
import {GroupByKeyPipe} from './pipes/group-by-key.pipe';
import {TicketsByTravRefPipe} from './pipes/tickets-by-trav-ref.pipe';
import {TicketsInfoComponent} from './components/tickets-info/tickets-info.component';
import {PassengerByRefPipe} from './pipes/passenger-by-ref.pipe';
import {ErrorAlertBlockComponent} from './components/error-alert-block/error-alert-block.component';
import { DatepickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
import {RemarksTemplateComponent} from './components/remarks-template/remarks-template.component';
import {SelectAirlineComponent} from './components/select-airline/select-airline.component';
import {FilterAirlinesPipe} from './pipes/filter-airlines.pipe';
import {RouterModule} from '@angular/router';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {MaskInputDirective } from './directives/mask-input.directive';
import {InputValidateDirective} from './directives/input-validate.directive';
import {TimepickerComponent} from './components/timepicker/timepicker.component';
import { TravelerTypePipe } from './pipes/traveler-type.pipe';
import { FlightInfoComponent } from './components/flight-info/flight-info.component';
import { GetApprovalStatusClassPipe } from './pipes/get-approval-status-class.pipe';
import { GetColorPipe } from './pipes/get-color.pipe';
import { IsSelectedSeatPipe } from './pipes/is-selected-seat.pipe';
import { DisableSelectSeatBySegmentPipe } from './pipes/disable-select-seat-by-segment.pipe';
import { GetStyleToAlignColumnPipe } from './pipes/get-style-to-align-column.pipe';
import { FilterSegmentsBySelectedSeatsPipe } from './pipes/filter-segments-by-selected-seats.pipe';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { SortFlightsByRoutesPipe } from './pipes/sort-flights-by-routes.pipe';
import { FadedPipe } from './pipes/faded.pipe';
import { DaysRangePipe } from './pipes/days-range.pipe';
import { GetDisabledPreferencesPipe } from './pipes/get-disabled-preferences.pipe';
import { IsIncludesPipe } from './pipes/is-includes.pipe';
import {RemarksComponent} from "../order/order-detail/remarks/remarks.component";
import { GetTicketNumberPipe } from './pipes/get-ticket-number.pipe';
import { AddSpaceBeforeCapitalLettersPipe } from './pipes/add-space-before-capital-letters.pipe';
import { GetImageDescriptionPipe } from './pipes/get-image-description.pipe';
import { GetAirlinePipe } from './pipes/get-airline.pipe';
import { NgSelect2Module } from 'ng-select2';
import { SortPassengersPipe } from './pipes/sort-passengers.pipe';
import { ShowAlternativeIdsComponent } from './components/show-alternative-ids/show-alternative-ids.component';
import { AircraftIconComponent } from './aircraft-icon/aircraft-icon.component';
import { GetAircraftIconPipe } from './pipes/get-aircraft-icon.pipe';
import { ShowApprovalStatusesComponent } from './components/show-approval-statuses/show-approval-statuses.component';
import { ExportToPnrComponent } from './components/export-to-pnr/export-to-pnr.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {TrimDirective} from "./directives/trim.directive";
import { ShowWarningsComponent } from './components/show-warnings/show-warnings.component';
import { MomentTimePipe } from './pipes/moment-time.pipe';
import { OfferPriceHeaderViewComponent } from './components/offer-price-header-view/offer-price-header-view.component';
import { FareRulesComponent } from './components/fare-rules/fare-rules.component';
import { GetCouponStatusCodePipe } from './pipes/get-coupon-status-code.pipe';
import { SeatsPerPsgAndSegmentPipe } from './pipes/seats-per-psg-and-segment.pipe';
import { SsrPerPsgAndSegmentPipe } from './pipes/ssr-per-psg-and-segment.pipe';
import { SegmentHasAncillariesPipe } from './pipes/segment-has-ancillaries.pipe';
import { GetProviderNamePipe } from './pipes/get-provider-name.pipe';
import { GroupByODAndMarketingNamePipe } from './pipes/group-by-od-and-marketing-name.pipe';
import { HoverClassDirective } from './directives/hover-class.directive';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { SortTravelersControlsPipe } from './pipes/sort-travelers-controls.pipe';
import { GetPassengerFromControlNamePipe } from './get-passenger-from-control-name.pipe';
import { CamelCaseToTitleCasePipe } from './camel-case-to-title-case.pipe';
import { SortPassengerControlNamesPipe } from './sort-passenger-control-names.pipe';
import { NotificationSeenCheckboxComponent } from './components/notification-seen-checkbox/notification-seen-checkbox.component';
import { GetDisruptionDescriptionPipe } from './pipes/get-disruption-description.pipe';
import { GetPresetTravelerTypePipe } from './pipes/get-preset-traveler-type.pipe';
import { PaymentInfoPipe } from './pipes/payment-info.pipe';
import { LettersNumbersOnlyDirective } from './directives/letters-numbers-only.directive';
import { UppercaseOnlyDirective } from './directives/uppercase-only.directive';
import { ItineraryDetailsComponent } from './components/itinerary-details/itinerary-details.component';
import { ServicesTableComponent } from './components/services-table/services-table.component';
import {DateWithPopoverComponent} from "./components/date-with-popover/date-with-popover.component";
import { GetPriceGroupIndexForSeatPipe } from './pipes/get-price-group-index-for-seat.pipe';
import { IsSeatExistsPipe } from './pipes/is-seat-exists.pipe';

@NgModule({
  imports: [
    NgPipesModule,
    CommonModule,
    VirtualScrollerModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2TelInputModule,
    RouterModule,
    NgSelect2Module,
    ClipboardModule,
  ],
  declarations: [
    AirlineImgComponent,
    LoaderComponent,
    SeatMapComponent,
    OfferExpirationComponent,
    GetMaterialIconsPipe,
    IsExistItemInArrayPipe,
    ExcludePassengersPipe,
    FormatFlightNumberPipe,
    GetSegmentRouteByIDPipe,
    JoinArrayPipe,
    VirtualScrollSelectComponent,
    PairsImpurePipe,
    ParseDurationPipe,
    GetLogoPathPipe,
    SplitPipe,
    MomentDatePipe,
    PadStartPipe,
    ValidateDirective,
    AirIconsInfoComponent,
    DestinationChooseItemComponent,
    ShowTaxesInfoComponent,
    GetKeyByValueFormObjPipe,
    IsInstanceOfObjectPipe,
    IsShowGridColumnCheckboxPipe,
    GroupedByFlightsPipe,
    ContainItemInArrayPipe,
    ShowPaxBreakdownInfoComponent,
    GetPassengerNameByRefPipe,
    PaymentFormComponent,
    OfferPriceViewComponent,
    MultiSelectComponent,
    InputDropdownComponent,
    CustomSelectComponent,
    SearchUmbrellaTravellersComponent,
    UmbrellaBarComponent,
    SearchProfilesComponent,
    ProfilesBarComponent,
    ReplacePipe,
    TimeRangePipe,
    DateRangePipe,
    GraphRangeDirective,
    Ng5OptionPipe,
    ConnectionListViewComponent,
    ServicePerPsgAndSegmentPipe,
    SeatsPerPsgAndSegmentPipe,
    SsrPerPsgAndSegmentPipe,
    SegmentHasAncillariesPipe,
    TooltipDirective,
    CountdownComponent,
    MainFlightSegmentComponent,
    NotificationComponent,
    ErrorAlertBlockComponent,
    BreadcrumbsComponent,
    ServiceListPreModalComponent,
    OfferGridComponent,
    ShowNumberOfItem,
    DiscountsFormComponent,
    FrequentFlyerNumberComponent,
    HtmlToStringParserPipe,
    FareTypeComponent,
    ShowProcessingComponent,
    BaggageComponent,
    CounterComponent,
    OrderStatusComponent,
    OptionsComponent,
    FilterOfferByCabinClassPipe,
    FilterSimpleOfferPipe,
    FareRulesCapacityInfoComponent,
    FareRulesFilterComponent,
    FilterLoaderComponent,
    RemoveInfantTravelersPipe,
    BaggageAllowanceInfoComponent,
    DisclosuresComponent,
    DisclosureIconComponent,
    DisclosureTableComponent,
    GroupByKeyPipe,
    TicketsByTravRefPipe,
    TicketsInfoComponent,
    PassengerByRefPipe,
    DatepickerRangeComponent,
    RemarksTemplateComponent,
    DateFormatPipe,
    InputValidateDirective,
    SelectAirlineComponent,
    FilterAirlinesPipe,
    TimepickerComponent,
    MaskInputDirective,
    TravelerTypePipe,
    FlightInfoComponent,
    GetApprovalStatusClassPipe,
    GetColorPipe,
    IsSelectedSeatPipe,
    DisableSelectSeatBySegmentPipe,
    GetStyleToAlignColumnPipe,
    CapitalizeFirstLetterPipe,
    SortFlightsByRoutesPipe,
    FilterSegmentsBySelectedSeatsPipe,
    FadedPipe,
    DaysRangePipe,
    GetDisabledPreferencesPipe,
    IsIncludesPipe,
    RemarksComponent,
    GetTicketNumberPipe,
    AddSpaceBeforeCapitalLettersPipe,
    GetImageDescriptionPipe,
    GetAirlinePipe,
    SortPassengersPipe,
    ShowAlternativeIdsComponent,
    AircraftIconComponent,
    GetAircraftIconPipe,
    ShowApprovalStatusesComponent,
    ExportToPnrComponent,
    TrimDirective,
    ShowWarningsComponent,
    MomentTimePipe,
    OfferPriceHeaderViewComponent,
    FareRulesComponent,
    GetCouponStatusCodePipe,
    GetProviderNamePipe,
    GroupByODAndMarketingNamePipe,
    HoverClassDirective,
    SortTravelersControlsPipe,
    GetPassengerFromControlNamePipe,
    CamelCaseToTitleCasePipe,
    SortPassengerControlNamesPipe,
    NotificationSeenCheckboxComponent,
    GetDisruptionDescriptionPipe,
    GetPresetTravelerTypePipe,
    PaymentInfoPipe,
    LettersNumbersOnlyDirective,
    UppercaseOnlyDirective,
    ItineraryDetailsComponent,
    ServicesTableComponent,
    DateWithPopoverComponent,
    GetPriceGroupIndexForSeatPipe,
    IsSeatExistsPipe,
  ],
  exports: [
    NgPipesModule,
    CommonModule,
    VirtualScrollerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelect2Module,
    ClipboardModule,
    SeatMapComponent,
    IsExistItemInArrayPipe,
    AirlineImgComponent,
    LoaderComponent,
    OfferExpirationComponent,
    ReplacePipe,
    ParseDurationPipe,
    GetPassengerNameByRefPipe,
    GetSegmentRouteByIDPipe,
    FormatFlightNumberPipe,
    GetLogoPathPipe,
    ExcludePassengersPipe,
    GroupedByFlightsPipe,
    PadStartPipe,
    SplitPipe,
    VirtualScrollSelectComponent,
    PairsImpurePipe,
    IsInstanceOfObjectPipe,
    GraphRangeDirective,
    JoinArrayPipe,
    GetMaterialIconsPipe,
    Ng5OptionPipe,
    MomentDatePipe,
    AirIconsInfoComponent,
    VirtualScrollSelectComponent,
    PairsImpurePipe,
    ValidateDirective,
    ValidateDirective,
    GetKeyByValueFormObjPipe,
    IsShowGridColumnCheckboxPipe,
    DestinationChooseItemComponent,
    ShowTaxesInfoComponent,
    ShowPaxBreakdownInfoComponent,
    PaymentFormComponent,
    OfferPriceViewComponent,
    MultiSelectComponent,
    ContainItemInArrayPipe,
    CustomSelectComponent,
    InputDropdownComponent,
    CustomSelectComponent,
    SearchUmbrellaTravellersComponent,
    UmbrellaBarComponent,
    SearchProfilesComponent,
    ProfilesBarComponent,
    TimeRangePipe,
    DateRangePipe,
    ConnectionListViewComponent,
    ServicePerPsgAndSegmentPipe,
    SeatsPerPsgAndSegmentPipe,
    SsrPerPsgAndSegmentPipe,
    SegmentHasAncillariesPipe,
    TooltipDirective,
    CountdownComponent,
    MainFlightSegmentComponent,
    NotificationComponent,
    ErrorAlertBlockComponent,
    BreadcrumbsComponent,
    Ng2TelInputModule,
    ServiceListPreModalComponent,
    OfferGridComponent,
    ShowNumberOfItem,
    DiscountsFormComponent,
    FrequentFlyerNumberComponent,
    ShowProcessingComponent,
    BaggageComponent,
    CounterComponent,
    HtmlToStringParserPipe,
    FareTypeComponent,
    OrderStatusComponent,
    OptionsComponent,
    FilterOfferByCabinClassPipe,
    FilterSimpleOfferPipe,
    FareRulesCapacityInfoComponent,
    FareRulesFilterComponent,
    FilterLoaderComponent,
    RemoveInfantTravelersPipe,
    BaggageAllowanceInfoComponent,
    DisclosuresComponent,
    DisclosureIconComponent,
    DisclosureTableComponent,
    TicketsByTravRefPipe,
    TicketsInfoComponent,
    DatepickerRangeComponent,
    RemarksTemplateComponent,
    InputValidateDirective,
    TimepickerComponent,
    DateFormatPipe,
    MaskInputDirective,
    TravelerTypePipe,
    FlightInfoComponent,
    GetApprovalStatusClassPipe,
    GetColorPipe,
    IsSelectedSeatPipe,
    DisableSelectSeatBySegmentPipe,
    FilterSegmentsBySelectedSeatsPipe,
    CapitalizeFirstLetterPipe,
    SortFlightsByRoutesPipe,
    FadedPipe,
    DaysRangePipe,
    GetDisabledPreferencesPipe,
    RemarksComponent,
    GetTicketNumberPipe,
    AddSpaceBeforeCapitalLettersPipe,
    GetImageDescriptionPipe,
    GetAirlinePipe,
    SortPassengersPipe,
    ShowAlternativeIdsComponent,
    ShowApprovalStatusesComponent,
    ExportToPnrComponent,
    TrimDirective,
    ShowWarningsComponent,
    MomentTimePipe,
    OfferPriceHeaderViewComponent,
    FareRulesComponent,
    GetProviderNamePipe,
    GroupByODAndMarketingNamePipe,
    SortTravelersControlsPipe,
    GetPassengerFromControlNamePipe,
    CamelCaseToTitleCasePipe,
    SortPassengerControlNamesPipe,
    NotificationSeenCheckboxComponent,
    GetDisruptionDescriptionPipe,
    GetPresetTravelerTypePipe,
    PaymentInfoPipe,
    LettersNumbersOnlyDirective,
    UppercaseOnlyDirective,
    ItineraryDetailsComponent,
    ServicesTableComponent,
    DateWithPopoverComponent,
    GetPriceGroupIndexForSeatPipe,
    IsSeatExistsPipe,
  ],
  providers: [
    LocalStorageService,
    GroupByKeyPipe,
    ParseDurationPipe,
    GetAirlinePipe,
    ServicePerPsgAndSegmentPipe,
    SeatsPerPsgAndSegmentPipe,
    SsrPerPsgAndSegmentPipe,
    PairsPipe,
  ]
})
export class SharedModule {
}
