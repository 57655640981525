<table class="table service-table">
  <thead>
  <tr>
    <th class="name">Name</th>
    <th class="descr">Description</th>
    <th>Price</th>
    <th>Action</th>
    <th *ngIf="atLeastOneHaveComment(services)">Comment</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let serviceObj of services">
    <td class="name">{{serviceObj.name}}</td>
    <td class="descr">
      <div *ngFor="let d of serviceObj.descriptions">
        - {{ d }}
      </div>
    </td>
    <td>
      <ng-container
        *ngIf="serviceObj.price.consumer.total > 0">{{ serviceObj.price.consumer.total | number :'1.2-2' }}
        {{ serviceObj.price.consumer.currency }}
      </ng-container>
      <ng-container *ngIf="serviceObj.price.consumer.total == 0">Free</ng-container>
    </td>
    <td>
      <ng-container *ngIf="serviceObj.ref != 'Seat'">
        <ng-container
          *ngIf="selectedServicesMapCopy[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences]">
            <span
              (click)="toggleServiceSelection(serviceObj, passengerRef)"
              class="btn btn-sm btn-success c-pointer">Selected
            </span>
        </ng-container>

        <ng-container
          *ngIf="!selectedServicesMapCopy[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences]">
            <span
              (click)="toggleServiceSelection(serviceObj, passengerRef)"
              class="btn btn-sm btn-primary c-pointer">Select
            </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="serviceObj.ref == 'Seat'">
        temporary disabled
      </ng-container>
    </td>
    <td *ngIf="atLeastOneHaveComment(services)">
      <ng-container *ngIf="showCommentInput(serviceObj, passengerRef)">
        <div class="d-flex flex-column gap-8">
          <ng-container *ngFor="let instruction of serviceObj.bookingInstructions.instructions; let idx = index">
            <input class="form-control"
                   [value]="getServiceValue(serviceObj, passengerRef, idx)"
                   [type]="instruction.type"
                   [placeholder]="instruction.text"
                   [pattern]="instruction.pattern"
                   (input)="handleCommentInput($event, serviceObj, passengerRef, instruction, idx)">
            <span
              class="error"
              *ngIf="validationErrors[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences + idx]">
                {{ validationErrors[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences + idx] }}
              </span>
          </ng-container>
        </div>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
