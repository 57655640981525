export const environment = {
  appName: 'bookingpad',
  name: 'prod',
  production: true,
  ndcApiEndpoint: 'https://api.airgateway.com/',
  ndcApiEndpointV2: 'https://api.airgateway.com/v2.0/',
  keycloakUrl: 'https://auth.airgateway.com',
  hashVersion: '09c01988',
  coviewAppKey: 'UtgDnY7wWIk',
  domain: '.com',
  default: {
    logoName: 'bookingpad.svg',
    title: 'BookingPad :: NDC-enabled Booking Tool',
    showSearch: true,
    showOrders: true,
    showSubagencies: true,
    showFooter: true,
    showPrivacy: true,
    showCoView: true,
    showSettings: true,
    showCorporates: true,
    showMaintenancePage: false
  },
  frontendDependencies: [{
    logoName: 'apglogo.png',
    subDomain: 'apg.bookingpad.pro',
    title: 'Orchestra Orders Tool',
    showSearch: false,
    showOrders: true,
    showSubagencies: false,
    showFooter: false,
    showPrivacy: false,
    showCoView: false,
    showSettings: false,
    showCorporates: false,
    showMaintenancePage: false
  }]
};
