<div class="modal-card card seats">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">Search History</h3>
      </div>
      <div class="col-auto">
        <button type="button" class="close" data-dismiss="modal"
                aria-hidden="true"
                (click)="emitDismiss.emit(true)">×
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf='searchType === SEARCH_TYPE.REGULAR'>
      <div *ngIf='webOffersData.length'>
        <table class="table table-default table-hover mb-0">
          <thead>
            <tr>
              <th>
                <div class="text-nowrap">Date of Search</div>
              </th>
              <th>
                <div>O&D</div>
              </th>
              <th>
                <div>Type</div>
              </th>
              <th *ngIf="showColumn.preset">
                <div>Preset</div>
              </th>
              <th>
                <div>PAX</div>
              </th>
              <th>
                <div>Departure</div>
              </th>
              <th>
                <div>Return</div>
              </th>
              <th>
                <div>Cabin</div>
              </th>
              <th *ngIf='showColumn.specialDiscounts'>
                <div>Discounts</div>
              </th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
          <ng-container *ngFor='let offer of webOffersData; index as i'>
            <tr class="cursor-pointer" (click)="searchSelectedOffer(offer)">
              <td>{{(offer.search_date | dateFormat: false :'HH:mm') | async}}</td>
              <td>
                <div class="d-flex align-items-center justify-content-center gap-5 text-nowrap">
                  <ng-container *ngFor="let dest of offer.origin_destination; last as last">
                    <span [appTooltip]="{airportCode: dest.departure?.airportCode, terminalName: dest.departure?.terminalName}">{{ dest.departure.airportCode }}</span>
                    <span class="material-icons fs-2" *ngIf="!last || offer.origin_destination.length === 1">
                      circle
                    </span>
                    <span *ngIf="offer.origin_destination.length === 1"
                          [appTooltip]="{airportCode: dest.arrival?.airportCode, terminalName: dest.arrival?.terminalName}">
                      {{ dest.arrival.airportCode }}
                    </span>
                  </ng-container>
                </div>
              </td>
              <td>
                <ng-container [ngSwitch]="offer.flight_type">
                  <div [ngbPopover]="flightTypeInfo"
                       [triggers]="'hover'"
                       popoverClass="flight-type-popover-wp"
                       placement="top"
                  >
                    <i *ngSwitchCase="'OW'" class="material-icons">arrow_right_alt</i>
                    <i *ngSwitchCase="'RT'" class="material-icons">swap_horiz</i>
                    <i *ngSwitchCase="'MC'" class="material-icons">linear_scale</i>
                  </div>
                  <ng-template #flightTypeInfo>
                      <div *ngSwitchCase="'OW'">One Way</div>
                      <div *ngSwitchCase="'RT'">Round Trip</div>
                      <div *ngSwitchCase="'MC'">Multi City</div>
                  </ng-template>
                </ng-container>
              </td>
              <td *ngIf="showColumn.preset">
                <div *ngIf="offer.preset; else noData">
                  {{offer.preset.title}}
                </div>
              </td>
              <td>
                <div *ngFor='let traveler of offer.pax | keyvalue'>
                  <div class="text-nowrap" *ngIf='traveler.value > 0'>
                    {{traveler.value}} {{traveler.key.toString() | uppercase}}
                  </div>
                </div>
              </td>
              <td>
                <div class="text-nowrap">{{(offer.origin_destination[0]?.departure?.date | dateFormat) | async}}</div>
              </td>
              <td>
                <div class="text-nowrap">
                  {{offer.flight_type === 'RT' && offer.origin_destination ? ((offer.origin_destination[1]?.departure?.date | dateFormat) | async) : '-'}}
                </div>
              </td>
              <td>
                <ng-container *ngFor="let cabinType of (cabinTypes | pairs );">
                  <div *ngIf='cabinType[0] === offer.cabin'>{{cabinType[1] !== 'All Cabins' ? cabinType[1] : 'All'}}</div>
                </ng-container>
              </td>
              <td *ngIf='showColumn.specialDiscounts'>
                <ng-container *ngIf='objectKeys(offer.special_discounts).length; else noData'>
                  <div *ngFor='let discount of offer.special_discounts | keyvalue'>
                    {{discount.key}}: <span class="fw-500">{{discount.value}}</span>
                  </div>
                </ng-container>
              </td>
              <td>
                <i class="material-symbols-outlined remove-icon"
                   title="Remove"
                   (click)="$event.stopPropagation(); removeOffer(offer.id, searchType)">
                  delete
                </i>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="alert-warning p-3 rounded" *ngIf='!webOffersData.length'>
        Search history is empty
      </div>
    </ng-container>
  </div>
  <div class="card-footer text-right">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="emitDismiss.emit(true);"
    >Close
    </button>
  </div>
</div>


<ng-template #noData>
  <div>
    -
  </div>
</ng-template>
