<div class="container mt-4" [class.wrapper]="isFirstSearch" [formGroup]="form" [@fadeInOnEnter]>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
      <div class="dropdowns-container d-flex align-items-center mb-3">
        <div class="d-flex">
          <div class="custom-dropdown" ngbDropdown #flightTypeDropdown="ngbDropdown">
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <span class="material-icons" [ngSwitch]="searchTypeOptions[form.get('type').value]">
                <ng-container *ngSwitchCase="'One-Way'">
                  arrow_right_alt
                </ng-container>
                <ng-container *ngSwitchCase="'Round-Trip'">
                  sync_alt
                </ng-container>
                <ng-container *ngSwitchCase="'Multi-City'">
                  linear_scale
                </ng-container>
              </span>
              {{ searchTypeOptions[form.get('type').value] }}
            </div>
            <div ngbDropdownMenu>
              <a
                *ngFor="let flightType of (searchTypeOptions | pairs);"
                (click)="setFlightType(flightType[0]); flightTypeDropdown.close();"
                class="dropdown-item"
              >
                {{ flightType[1] }}
              </a>
            </div>
          </div>

          <div class="custom-dropdown presets-dropdown"
               [class.selected]="selectedPreset && (hubAgenciesService.mandatoryPreset || selectedPreset === hubAgenciesService.defaultPreset)"
               [class.disabled]="hubAgenciesService.mandatoryPreset"
               ngbDropdown
               autoClose="outside"
               #presetsDropdown="ngbDropdown">
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <span class="material-symbols-outlined">
              tune
            </span>
              {{ selectedPreset?.title || 'Preset' }}
            </div>
            <div ngbDropdownMenu>
              <a class="dropdown-item dark-red-color" (click)="resetPreset(); presetsDropdown.close()">None</a>
              <a
                *ngFor="let preset of hubAgenciesService.presets;"
                (click)="onSelectedPreset(preset); presetsDropdown.close()"
                class="dropdown-item"
              >
                <span>{{ preset.title }}</span>
              </a>
              <a *ngIf="canEditPresets"
                 class="dropdown-item edit-item d-flex align-items-center"
                 [routerLink]="['/presets']">
                <span class="material-icons edit-icon">
                  edit
                </span>
                <span>Edit Presets</span>
              </a>
              <a class="dropdown-item refresh-item d-flex align-items-center" (click)="refreshPresetList()">
                <span class="material-icons refresh-icon" [@rotate]="presetListRefreshToggled">
                  refresh
                </span>
                <span>Reload</span>
              </a>
            </div>
          </div>

          <div class="custom-dropdown travelers-dropdown" ngbDropdown>
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <span class="material-symbols-outlined">
              person
            </span>
              {{ travelersCount }}
            </div>
            <div formGroupName="travelers" class="travelers-dropdown-menu" ngbDropdownMenu>
              <div class="d-flex align-items-center py-3 px-2 lh-16" *ngFor="let control of form.get('travelers')['controls'] | keyvalue | sortTravelersControls: ptcsMap; let idx = index;">
                <div class="col">
                  <div>{{control.key | uppercase}}</div>
                  <div *ngIf="control.key === ptcsMap.YAD" class="yad-hint">Aged 12-15</div>
                </div>
                <div class="col d-flex align-items-center gap-10">
                  <button
                    class="travelers-button remove-passenger-button"
                    [disabled]="control.value.value === 0"
                    (click)="passengerRemove($event, control.key); actionChangeTravelers()">
                    <i class="material-icons">remove</i>
                  </button>
                  <span>{{ control.value.value}}</span>
                  <button class="travelers-button add-passenger-button"
                          [disabled]="control.key === ptcsMap.INF ? isAddInfantButtonDisable : isAddPassengerButtonDisable"
                          (click)="passengerAdd($event, control.key); actionChangeTravelers()"
                  >
                    <i class="material-icons">add</i>
                  </button>
                  <input
                    [formControlName]="control.key"
                    type="hidden"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="custom-dropdown" ngbDropdown #ticketTypeDropdown="ngbDropdown">
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <span class="material-symbols-outlined">
              flight_class
            </span>
              {{ cabinTypes[form.get('cabin').value]}}
            </div>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let cabinType of (cabinTypes | keyvalue | reverse);">
                <a
                  *ngIf="cabinType.key !== '100'"
                  (click)="selectedCabinValue(cabinType.key); ticketTypeDropdown.close();"
                  class="dropdown-item"
                >
                  {{ cabinType.value }}
                </a>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="['airgateway', 'umbrella'].indexOf(ls.profilesType) > - 1">
            <ng-container
              *ngTemplateOutlet="ls.profilesType === 'airgateway' ? profilesSearch : umbrellaSearch">
            </ng-container>

            <ng-template #profilesSearch>
              <div class="custom-dropdown corporate-dropdown" ngbDropdown #corporateDropdown="ngbDropdown">
                <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <span class="material-symbols-outlined">
                corporate_fare
              </span>
                  {{selectedCorporate || 'Corporate'}}
                </div>
                <div class="corporate-dropdown-menu p-3" ngbDropdownMenu>
                  <div class="d-flex align-items-center gap-5" *ngIf="!isChangeCorporate">
                    <span>Selected corporate:</span>
                    <span class="red-color">{{selectedCorporate}}</span>
                    <span class="material-symbols-outlined" (click)="onSelectedCorporate(null);">
                    close
                  </span>
                  </div>
                  <ng-container *ngIf="isChangeCorporate">
                    <app-profiles-bar [useNewDesign]="true" (emitIsSelectedCompany)="onSelectedCorporate($event); corporateDropdown.close()"></app-profiles-bar>
                  </ng-container>
                </div>
              </div>
            </ng-template>

            <ng-template #umbrellaSearch>
              <div class="custom-dropdown corporate-dropdown" ngbDropdown #corporateDropdown="ngbDropdown">
                <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <span class="material-symbols-outlined">
                corporate_fare
              </span>
                  {{selectedCompany || 'Corporate'}}
                </div>
                <div class="corporate-dropdown-menu p-3" ngbDropdownMenu>
                  <div class="d-flex align-items-center gap-5" *ngIf="!isChangeCompany">
                    <span>Selected corporate:</span>
                    <span class="red-color">{{selectedCompany}}</span>
                    <span class="material-symbols-outlined" (click)="onSelectedCompany(null);">
                  close
                </span>
                  </div>
                  <ng-container *ngIf="isChangeCompany">
                    <app-umbrella-bar [useNewDesign]="true" (emitIsSelectedCompany)="onSelectedCompany($event); corporateDropdown.close()"></app-umbrella-bar>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>

        <div class="d-flex">
          <div class="custom-dropdown special-discounts-dropdown" ngbDropdown>
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <span class="material-symbols-outlined">
              percent
            </span>
              Special discounts
              <span *ngIf="form.get('residents').value && form.get('large_family').value; else oneValue" class="counter">x2</span>
              <ng-template #oneValue>
                <span *ngIf="form.get('residents').value || form.get('large_family').value" class="counter">x1</span>
              </ng-template>
            </div>
            <div class="special-discounts-dropdown-menu p-3" ngbDropdownMenu>
              <div class="form-group">
                <label for="residentsIn">Resident in:</label>

                <select class="form-control"
                        id="residentsIn"
                        formControlName="residents"
                        [ngStyle]="{'color': !form.get('residents').value ? '#999999' : '' }"
                >
                  <option hidden value="">Select your residency</option>
                  <option *ngFor="let item of residentsList | keyvalue" [value]="item.key">{{ item.value }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="largeFamily">Large family:</label>
                <select class="form-control"
                        id="largeFamily"
                        formControlName="large_family"
                        [ngStyle]="{'color': !form.get('large_family').value ? '#999999' : '' }"
                >
                  <option hidden selected value="">Select your family size</option>
                  <option *ngFor="let familyType of largeFamilyList | keyvalue" [value]="familyType.key">{{ familyType.value }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="custom-dropdown providers-dropdown" ngbDropdown *ngIf="helpers.allProviders?.length">
            <div class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
              <span class="material-symbols-outlined">
                travel
              </span>
              Providers
              <span *ngIf="selectedProviders.length && selectedProviders.length !== helpers.allProviders?.length"
                    class="counter">
                x{{selectedProviders.length}}
              </span>
            </div>
            <div class="providers-dropdown-menu p-3" ngbDropdownMenu>
              <div class="checkbox-wrapper">
                <label class="checkbox custom-checkbox-1"
                       [for]="'dropdown-all'">
                  <input type="checkbox" [id]="'dropdown-all'" [checked]="!selectedProviders?.length" (change)="clearProviders(); allCheckbox.checked = true" #allCheckbox/>
                  <div class="checkbox__checkmark"></div>
                  <div class="checkbox__body">All</div>
                </label>
              </div>
              <div class="checkbox-wrapper text-nowrap" *ngFor="let provider of helpers.allProviders | orderBy; last as last">
                <label class="checkbox custom-checkbox-1"
                       [for]="'dropdown' + provider"
                       [class.mb-0]="last">
                  <input type="checkbox" [id]="'dropdown' + provider" [checked]="selectedProviders.includes(provider)" (change)="setProviders(provider)"/>
                  <div class="checkbox__checkmark"></div>
                  <div class="checkbox__body">{{ provider | getProviderName }}</div>
                </label>
                <span class="only-btn" (click)="$event.stopPropagation(); selectOneProvider(provider)">only</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="search-container d-flex justify-content-between gap-12 mb-3">
        <div class="d-flex flex-column gap-12 w-100">
          <ng-container *ngFor="let row of searchRows; let i = index; first as first">
            <div class="d-flex gap-12">
              <div class="airport-input-container" *ngIf="!searchAirportsEnabled">
                <input
                  type="text"
                  class="airport-input"
                  [ngClass]="form.get('departure_code[' + row.controlIndex + ']').value ? 'value' : 'placeholder'"
                  [value]="form.controls['departure_code[' + row.controlIndex +']'].value"
                  (input)="onSelectValueChanged('departure_code[' + row.controlIndex +']', $event.target.value, i)"
                  placeholder="Origin"
                >
              </div>
              <div class="virtual-scroll" *ngIf="searchAirportsEnabled">
                <app-virtual-scroll-select
                  [data]="airports"
                  [uniqueSelectId]="1"
                  [value]="form.controls['departure_code[' + row.controlIndex +']'].value"
                  [hasError]="form.controls['departure_code[' + row.controlIndex +']'].invalid"
                  [searchFull]="searchAirportsByFullNameEnabled"
                  [placeholder]="'Origin'"
                  (emitValueChanged)="onSelectValueChanged('departure_code[' + row.controlIndex +']', $event.id, i)"
                  (emitSearch)="clickSearch()"
                >
                </app-virtual-scroll-select>
              </div>

              <span class="btn btn-icon btn-swap" (click)="actionSwap(row.controlIndex)">
                <i class="material-icons">swap_horiz</i>
              </span>

              <div class="airport-input-container" *ngIf="!searchAirportsEnabled">
                <input
                  type="text"
                  class="airport-input"
                  [ngClass]="form.get('arrival_code[' + row.controlIndex + ']').value ? 'value' : 'placeholder'"
                  [value]="form.get('arrival_code[' + row.controlIndex + ']').value"
                  (input)="onSelectValueChanged('arrival_code[' + row.controlIndex + ']', $event.target.value, i)"
                  placeholder="Destination"
                >
              </div>
              <div class="virtual-scroll" *ngIf="searchAirportsEnabled">
                <app-virtual-scroll-select
                  [data]="airports"
                  [uniqueSelectId]="2"
                  [value]="form.controls['arrival_code[' + row.controlIndex +']'].value"
                  [hasError]="form.controls['arrival_code[' + row.controlIndex +']'].invalid"
                  [searchFull]="searchAirportsByFullNameEnabled"
                  [placeholder]="'Destination'"
                  (emitValueChanged)="onSelectValueChanged('arrival_code[' + row.controlIndex + ']', $event.id, i)"
                  (emitSearch)="clickSearch()"
                >
                </app-virtual-scroll-select>
              </div>

              <div class="input-group datepicker-group">
                <div class="input-group-append">
                  <span class="btn btn-icon btn-event pr-0" (click)="d.toggle()">
                    <i class="material-icons">event</i>
                  </span>
                </div>
                <input
                  [formControlName]="'departure_date[' + row.controlIndex + ']'"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  (keydown.enter)="d.toggle()"
                  (dateSelect)="onDateDepartureSelect(row.controlIndex, i, $event)"
                  [minDate]="minDates['departure_date[' + row.controlIndex + ']'] || currentDate"
                  [dayTemplate]="t"
                  [displayMonths]="2"
                  [readOnly]="true"
                  autocomplete="off"
                  class="form-control form-control-appended"
                  [class.value]="form.get('departure_date[' + row.controlIndex + ']').value"
                  placeholder="Dep. Date"
                  name="dp"
                  placement="bottom"
                  #departureDates
                >
                <div class="input-group-prepend">
                  <span class="input-group-text" (click)="changeDate(row.controlIndex, i, false, true)">
                    <span class="material-symbols-outlined">navigate_before</span>
                  </span>
                  <span class="input-group-text pr-3" (click)="changeDate(row.controlIndex, i, true, true)">
                    <span class="material-symbols-outlined">navigate_next</span>
                  </span>
                </div>
                <ng-template #t let-date="date" let-focused="focused">
                  <div class="custom-day btn-light"
                       [class.focused]="focused"
                       [class.faded]="date | faded: form.controls : searchRows : i : hoveredDate : form.controls['type'].value"
                       [class.range]="date | daysRange: form.controls : searchRows : i : form.controls['type'].value"
                       (mouseenter)="hoveredDate = date"
                       (mouseleave)="hoveredDate = null"
                  >
                    {{ date.day }}
                  </div>
                </ng-template>
                <ng-container *ngIf="form.get('type').value === 'RT'">
                  <input
                    [formControlName]="'arrival_date[' + row.controlIndex + ']'"
                    ngbDatepicker
                    #dd="ngbDatepicker"
                    (click)="dd.toggle()"
                    [minDate]="minDates['arrival_date[' + row.controlIndex + ']'] || currentDate"
                    (keydown.enter)="dd.toggle()"
                    [dayTemplate]="t"
                    [displayMonths]="2"
                    [readOnly]="true"
                    class="form-control pl-0"
                    [class.value]="form.get('arrival_date[' + row.controlIndex + ']').value"
                    placeholder="|  Return"
                    autocomplete="off"
                    name="dp2"
                    placement="bottom"
                    #arrivalDate
                  >
                  <div class="input-group-prepend">
                    <span class="input-group-text" (click)="changeDate(row.controlIndex, i, false, false)">
                      <span class="material-symbols-outlined">navigate_before</span>
                    </span>
                    <span class="input-group-text pr-3" (click)="changeDate(row.controlIndex, i, true, false)">
                      <span class="material-symbols-outlined">navigate_next</span>
                    </span>
                  </div>
                  <ng-template #t let-date="date" let-focused="focused">
                    <div class="custom-day btn-light"
                         [class.focused]="focused"
                         [class.faded]="date | faded: form.controls : searchRows : i : hoveredDate : form.controls['type'].value : true"
                         [class.range]="date | daysRange: form.controls : searchRows : i : form.controls['type'].value"
                         (mouseenter)="hoveredDate = date"
                         (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day }}
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <div class="d-flex mw-60" *ngIf="form.get('type').value !== 'RT'">
                <button *ngIf="i > 0"
                  (click)="actionRemoveRow(i)"
                  class="btn btn-icon btn-outline-primary w-48">X
                </button>
              </div>
            </div>

            <ng-container *ngIf="showErrorMatchAirports === row.controlIndex; else second">
              <div class="alert-danger rounded p-1 pl-3">
                Origin and Destination can not be the same.
              </div>
            </ng-container>

            <ng-template #second>
              <ng-container *ngIf="validateTriggered">
                <ng-container *ngIf="!form.get('departure_code[' + row.controlIndex + ']').value; else third">
                  <div class="alert-danger rounded p-1 pl-3">
                    Origin field can not be empty
                  </div>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-template #third>
              <ng-container *ngIf="!form.get('arrival_code[' + row.controlIndex + ']').value; else fourth">
                <div class="alert-danger rounded p-1 pl-3">
                  Destination field can not be empty
                </div>
              </ng-container>
            </ng-template>

            <ng-template #fourth>
              <ng-container *ngIf="form.get('departure_date[' + row.controlIndex + ']').errors; else fifth">
                <div class="alert-danger rounded p-1 pl-3">
                  Please select a Return departure date
                </div>
              </ng-container>
            </ng-template>

            <ng-template #fifth>
              <div class="alert-danger rounded p-1 pl-3" *ngIf="form.get('arrival_date[' + row.controlIndex + ']').errors">
                Please select a Return departure date
              </div>
            </ng-template>

          </ng-container>
        </div>
        <div class="right-search-container d-flex gap-12">
          <button class="btn btn-icon btn-search"
                  [disabled]="showErrorMatchAirports"
                  (click)="allowNewSearch ? clickSearch() : actionAbort()"
                  (keyup.enter)="clickSearch()"
                  [class.is-active-search]="!allowNewSearch">
            <ng-container *ngIf="!allowNewSearch">
              <div class="loader-wrapper loader-wrapper-small" [hidden]="allowNewSearch">
                <app-loader></app-loader>
              </div>
              <span class="ml-3 red-color">Abort</span>
            </ng-container>
            <ng-container *ngIf="allowNewSearch">
              <i class="fas fa-search"></i>
              <span class="ml-3">Search</span>
            </ng-container>
          </button>

          <div class="new-offers-wp" *ngIf="isShowOffersForUpdateBtn && newOffersCount">
            <button class="btn btn-icon btn-autorenew">
              <i class="material-icons"
                 [ngClass]="{'rotating': showUpdateSpinner}"
                 placement="top"
                 [disableTooltip]="isShowUpdatePopup"
                 ngbTooltip="New Offers: {{newOffersCount}}"
                 (click)="onUpdateOffers()">
                autorenew
              </i>
            </button>
            <div class="info-popup" *ngIf="isShowUpdatePopup && isShowOffersForUpdateBtn && newOffersCount">

              <div class="info-text">
                There are new results ({{newOffersCount}})
              </div>

              <div class="info-btn">
                <button class="btn-later" (click)="onUpdateOffersLater()">Later</button>
                <button class="btn-update" (click)="onUpdateOffers()">Refresh</button>
              </div>

            </div>
          </div>

          <button class="btn btn-icon btn-restore" *ngIf='webOffersHistoryData.length'
                  (click)="open(offerHistoryModal, 'xl')">
            <span class="material-icons-outlined">
              restore
            </span>
          </button>
        </div>
      </div>
      <div class="d-flex mb-3" *ngIf="form.get('type').value === 'MC'">
        <button
          class="btn btn-tonal-primary d-flex align-items-center gap-6 add-flight-btn" (click)="actionAddRow()">
          <span class="material-symbols-outlined fs-16 font-weight-bold lh-15">
            add
          </span>
          <span> Add flight </span>
        </button>
      </div>
      <div *ngIf="searchAgainOwner" class="my-3 search-all">
        This search is performed against <strong>{{searchAgainOwner}}</strong> airline
        <a (click)="resetSearchAgain()"> (search all airlines)</a>
      </div>
    </div>
  </div>

  <div class="alert-danger rounded p-3 mt-5 mb-4" *ngIf="errorMessage">
    {{errorMessage}}
  </div>

  <div class="alert-warning rounded mt-5 p-3 mb-4" *ngIf="isDoneSearch && !offers.length">
    No offers found
  </div>
</div>

<ng-template #offerHistoryModal let-c="close" let-d="dismiss">
  <app-offer-history-modal
    (emitDismiss)="d('closed')"
    (emitRemoveOffer)="onRemoveOffer($event)"
    (emitSearchOffer)='onSearchOffer($event)'
    [searchType]='searchType'
    [webOffersData]='webOffersHistoryData'
  >
  </app-offer-history-modal>
</ng-template>

