<div class="container mt-3">
  <div class="row">
    <div class="col-md-7 mx-auto">
      <div id="booking-stepper" class="bs-stepper" *ngIf="offer">
        <div class="bs-stepper-header">
          <div class="step"
               [class.active]="selectedStep == bookingSteps.OFFER"
               [class.completed]="isOfferStepCompleted"
               (click)="stepSelect(bookingSteps.OFFER)"
          >
            <button class="step-trigger">
              <span class="bs-stepper-circle">
                <ng-container *ngIf="!isOfferStepCompleted; else stepCompleted">
                  <span>1</span>
                </ng-container>
              </span>
              <span class="bs-stepper-label">Offer</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step"
               [class.active]="selectedStep == bookingSteps.PAX"
               [class.disabled]="numberOfCompletedSteps < bookingSteps.PAX"
               [class.completed]="isPaxStepCompleted"
               (click)="stepSelect(bookingSteps.PAX)"
          >
            <button class="step-trigger">
              <span class="bs-stepper-circle">
                <ng-container *ngIf="!isPaxStepCompleted; else stepCompleted">
                  <span>2</span>
                </ng-container>
              </span>
              <span class="bs-stepper-label">PAX</span>
            </button>
          </div>
          <ng-container *ngIf="hasRemarkTemplates">
            <div class="line"></div>
            <div class="step"
                 [class.active]="selectedStep == bookingSteps.REMARKS"
                 [class.disabled]="numberOfCompletedSteps < bookingSteps.REMARKS"
                 [class.completed]="isRemarksStepCompleted"
                 (click)="stepSelect(bookingSteps.REMARKS)"
            >
              <button class="step-trigger">
                <span class="bs-stepper-circle">
                  <ng-container *ngIf="!isRemarksStepCompleted; else stepCompleted">
                    <span>3</span>
                  </ng-container>
                </span>
                <span class="bs-stepper-label">Remarks</span>
              </button>
            </div>
          </ng-container>
          <div class="line"></div>
          <div class="step"
               [class.active]="selectedStep == bookingSteps.RESERVATION"
               [class.disabled]="isReservationStepDisabled"
               (click)="stepSelect(bookingSteps.RESERVATION)"
          >
            <button class="step-trigger">
              <span class="bs-stepper-circle">
                <span>{{hasRemarkTemplates ? '4' : '3'}}</span>
              </span>
              <span class="bs-stepper-label">Reservation</span>
            </button>
          </div>
        </div>
        <ng-template #stepCompleted>
          <i class="fas fa-check"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-end" *ngIf="offer">
        <div class='header-actions'>
          <div
            class="export-btn"
            [ngbPopover]="exportFile"
            #exportPopover="ngbPopover"
            placement="bottom"
            [autoClose]="'outside'"
          >Export</div>

          <ng-template #exportFile>
            <div class="order-files_list-title">Export to:</div>
            <div class="order-files_list">
              <div class="order-files_list-item">
                <app-export-to-pnr [order]="offer" [popover]="exportPopover"></app-export-to-pnr>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="mt-3">
        <ng-container *ngIf="responseWarning.length">
          <div #warnings class="alert alert-warning p-3 mb-3 rounded warnings"
               *ngIf="showWarnings"
               [ngStyle]="{'max-height': !allWarningsAreShown ? '190px' : 'fit-content' }" [class.faded]="!allWarningsAreShown">
            <ul>
              <li *ngFor="let item of responseWarning"
                  [innerHTML]="item | htmlToStringParser | replace: '\n':'<br\/>'">
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end mb-3">
            <div class="btn btn-secondary p-2"
                 [class.mr-3]="isWarningsReadBtnNeeded && showWarnings"
                 (click)="toggleDisplayWarnings()">
              {{showWarnings ? 'Hide' : 'Show warnings'}}
            </div>
            <div class="btn btn-secondary p-2"
                 *ngIf="isWarningsReadBtnNeeded && showWarnings"
                 (click)="toggleReadWarnings()">
              Read {{!allWarningsAreShown ? 'more' : 'less'}}
            </div>
          </div>
        </ng-container>
        <div *ngIf="responseError && !responseError.message">
          <div class="alert-danger p-3 mb-3 rounded">
            {{ responseError }}
          </div>
          <div class="retry-line p-3 mb-3">
            <button
              type="button"
              class="btn btn-primary"
              (click)="refresh()">
              Refresh the page
            </button>
          </div>
        </div>
        <div *ngIf="responseError.message">
          <div class="alert-{{ responseError.type }} p-3 mb-3 rounded">
            {{ responseError.message }}
          </div>
          <div class="retry-line p-3 mb-3">
            <button
              type="button"
              class="btn btn-primary"
              [routerLink]="['/search']">
              Close
            </button>
          </div>
        </div>
        <div class="loader-wrapper" *ngIf="showLoader">
          <app-loader></app-loader>
        </div>

        <div class="offering card" *ngIf="offer && !showLoader && !responseError">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <div class="filter-image mb-3">
                  <app-airline-img
                    [airlineID]="offer.owner"
                    [title]="offer.owner | getImageDescription: offer.flights"
                  ></app-airline-img>
                </div>
                <div style="font-size: 1.1rem; max-width: 300px">
                  <ng-container *ngFor="let flight of offer.flights; last as last">
                    <span [appTooltip]="{airportCode: flight.departure.airportCode, terminalName: flight.departure.terminalName}">
                      {{flight.departure.airportCode}}
                    </span>
                    →
                    <span [appTooltip]="{airportCode: flight.arrival.airportCode, terminalName: flight.arrival.terminalName}">
                      {{flight.arrival.airportCode}}{{!last ? ',' : ''}}
                    </span>
                  </ng-container>
                </div>
                <div class="smaller-text">Class:<b>{{ getOfferClassCodes() }} </b></div>
              </div>
              <div class="col text-center">
                <div *ngIf="getTravelersInfo">
                  PAX: <b>{{ getTravelersInfo() }}</b>
                </div>
              </div>
              <div class="col">
                <div class="float-right">
                  <app-show-pax-breakdown-info
                    [consumerPrice]="offer.price.consumer"
                    [passengers]="offer.passengers">
                    <div class="bigger-price">
                      <span class="font-weight-bold">{{offer.price.consumer.total | number :'1.2-2'}}</span>
                      {{offer.price.consumer.currency}}
                    </div>
                  </app-show-pax-breakdown-info>
                  <app-show-taxes-info
                    [taxesInfo]="offer.price.consumer.tax.breakdown"
                    [currency]="offer.price.consumer.currency">
                    <div class="smaller-price">
                      Taxes: {{offer.price.consumer.tax.total | number :'1.2-2'}} {{offer.price.consumer.currency}}
                    </div>
                  </app-show-taxes-info>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" [ngSwitch]="selectedStep" [formGroup]="form">
            <ng-container *ngSwitchCase="bookingSteps.OFFER">
              <app-offer-price-view
                [offer]="offer"
                [travelers]="travelers"
                [fareRules]="fareRules"
                [isShowDisclosureTable]="true"
                [useNewDesign]="true"
                [seatsPerSegment]="showSeats"
                (emitRemoveSeat)="onRemoveSeat($event)"
              ></app-offer-price-view>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.REMARKS">

              <ng-container *ngIf="corporateRemarkTemplates.length; else agencyRemarks">
                <ng-container *ngIf="agencyRemarkTemplates.length">
                  <h3 class="text-center mb-0">
                    <span class="remark-name">Agency</span> Remarks <span class="text-primary" *ngIf="hasMandatoryRemarks">*</span>
                  </h3>
                  <ng-container [ngTemplateOutlet]="agencyRemarks"></ng-container>
                </ng-container>
                <h3 class="text-center mb-0" [class.mt-5]="agencyRemarkTemplates.length">
                  <span class="remark-name">Corporate</span> Remarks <span class="text-primary" *ngIf="hasMandatoryRemarks">*</span>
                </h3>
                <app-remarks
                  (emitUpdatedRemark)="updateCorporateRemarks($event)"
                  (emitTemplateSelected)="isCorporateRemarksTemplateSelected = $event"
                  [remark]="corporateRemarksData || offer.remarks"
                  [templates]="corporateRemarkTemplates"
                  [orderId]="offer.id"
                  [orderInfo]="offerInfo"
                  [isRemarksAfterOrderCreation]="false"
                  [mandatoryRemarks]="mandatoryCorporateRemarks"
                  [saveTriggered]="remarksSaveTriggered"
                ></app-remarks>
              </ng-container>

              <ng-template #agencyRemarks>
                <app-remarks
                  (emitUpdatedRemark)="updateAgencyRemarks($event)"
                  (emitTemplateSelected)="isAgencyRemarksTemplateSelected = $event"
                  [remark]="agencyRemarksData || offer.remarks"
                  [templates]="agencyRemarkTemplates"
                  [orderId]="offer.id"
                  [orderInfo]="offerInfo"
                  [isRemarksAfterOrderCreation]="false"
                  [mandatoryRemarks]="mandatoryAgencyRemarks"
                  [saveTriggered]="remarksSaveTriggered"
                ></app-remarks>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.PAX">
              <div *ngIf="ls.profilesType === 'umbrella'; else corporate">
                <div class="form-row">
                  <div *ngIf="!umbrellaCompanyShow && !selectedCompany"
                       class="form-group col-md-6">
                    <label>&nbsp;</label>
                    <app-umbrella-bar (emitIsSelectedCompany)="onSelectedCompany($event)"></app-umbrella-bar>
                  </div>
                  <div class="selected-corporate col-md-6" *ngIf="selectedCompany">
                    <div class="is-selected-corporate" *ngIf="!isChangeCompany">
                      <img src="assets/img/umbrella/umbrella-logo.svg"
                           class="hs-image-widget umbrella-icon">
                      <span class="selected-info">Selected corporate: </span>
                      <span class="selected-corporate-span">
                      <span class="click-select">{{selectedCompany}}</span>
                      <span class="click-close" *ngIf="!disableCloseCompany" (click)="onSelectedCompany(null);"><i
                        class="material-icons ml-1">close</i></span>
                    </span>
                    </div>
                    <div *ngIf="isChangeCompany" class="umbrella-line">
                      <app-umbrella-bar (emitIsSelectedCompany)="onSelectedCompany($event)"></app-umbrella-bar>
                    </div>
                  </div>
                  <ng-container [ngTemplateOutlet]="loyaltyProgramAccountTmpl"></ng-container>
                </div>
              </div>
              <ng-template #corporate>
                <div class="form-row">
                  <div *ngIf="!corporateShow && !selectedCorporate && ls.profilesType === 'airgateway'"
                       class="form-group col-md-6">
                    <label>&nbsp;</label>
                    <app-profiles-bar (emitIsSelectedCompany)="onSelectedCorporate($event)"></app-profiles-bar>
                  </div>
                  <div *ngIf="selectedCorporate && ls.profilesType === 'airgateway'" class="selected-corporate col-md-6">
                    <div class="is-selected-corporate" *ngIf="!isChangeCorporate">
                      <span class="selected-info">Corporate: </span>
                      <span class="selected-corporate-span">
                        <span class="click-select">{{selectedCorporate}}</span>
                        <span class="click-close" *ngIf="!disableCloseCorporate" (click)="onSelectedCorporate(null);"><i
                          class="material-icons ml-1">close</i></span>
                      </span>
                    </div>
                    <div *ngIf="isChangeCorporate" class="umbrella-line">
                      <app-profiles-bar (emitIsSelectedCompany)="onSelectedCorporate($event)"></app-profiles-bar>
                    </div>
                  </div>
                  <ng-container [ngTemplateOutlet]="loyaltyProgramAccountTmpl"></ng-container>
                </div>
              </ng-template>

              <ng-template #loyaltyProgramAccountTmpl>
                <div class="form-group col-md-6" *ngIf="isLoyaltyProgramAccountEnabled()">
                  <label>Corporate Loyalty Program</label>
                  <input
                    *ngIf="!loyaltyProgramAccounts.length"
                    [validate]="form.get('loyaltyProgramAccount').dirty || validateTriggered"
                    type="text"
                    formControlName="loyaltyProgramAccount"
                    autocomplete="false"
                    placeholder="OBXXXXX..."
                    class="form-control">

                  <ng-container *ngIf="loyaltyProgramAccounts.length">
                    <input type="text"
                           placeholder="OBXXXXX..."
                           id="selectGroups"
                           class="form-control corporate-loyalty-program-control custom-select"
                           formControlName="loyaltyProgramAccount"
                           [validate]="form.get('loyaltyProgramAccount').dirty || validateTriggered"
                           list="loyaltyProgramAccountsList">
                    <datalist id="loyaltyProgramAccountsList">
                      <option *ngFor="let account of loyaltyProgramAccounts" [value]="account">{{ account }}
                    </datalist>
                  </ng-container>

                </div>
              </ng-template>

              <ngb-tabset  #tabs="ngbTabset" [activeId]="firstTravelerID"
                             [destroyOnHide]="false">
                <ng-container *ngFor="let travelerType of (travelers | pairs)">
                  <ng-container *ngFor="let idx of seatMapService.createRange(travelerType[1]);">
                    <ngb-tab
                      *ngIf="('passenger_' + travelerType[0] + '_' + idx) as psgKey"
                      id="{{ psgKey }}"
                      [title]="'Passenger ' + travelerType[0].toUpperCase() + ' ' +  (idx + 1)"
                    >
                      <ng-template ngbTabTitle>
                        <span class="tab-unchecked"></span>
                        <i *ngIf="form.get(psgKey).status === 'INVALID'" class="material-icons remark-icon">
                          error_outline
                        </i>
                        <div *ngIf="form.get(psgKey).status === 'VALID'" class="successful-mark-icon">
                        </div>
                      </ng-template>
                      <ng-template ngbTabContent>
                        <div [formGroupName]="psgKey" class="sentry-mask needs-validation">
                          <div class="form-row" formGroupName="data">
                            <div class="form-group form-group-sm col-md-6"
                                 *ngIf="form.get(psgKey + '.data.name') as name">
                              <label><span class="text-primary"><b>*</b></span> Given Name</label>
                              <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                <input [validate]="name.dirty || validateTriggered"
                                       type="text"
                                       formControlName="name"
                                       class="form-control"
                                       autocomplete="false"
                                       placeholder="Alex"/>
                              </ng-container>
                              <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                <app-search-umbrella-travellers
                                  [isValid]="!name.invalid && name.dirty"
                                  [isInvalid]="name.invalid && name.dirty"
                                  [travellerInputValue]="this.form.value[psgKey]?.data?.name"
                                  [isDisabled]="isChangeCompany"
                                  [passengerFormKey]="psgKey"
                                  (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchString)="onChangeInputValue($event, 'name', psgKey)"
                                ></app-search-umbrella-travellers>
                              </ng-container>
                              <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                <app-search-profiles
                                  [isValid]="!name.invalid && name.dirty"
                                  [isInvalid]="name.invalid && name.dirty"
                                  [inputValue]="this.form.value[psgKey]?.data?.name"
                                  [isDisabled]="isChangeCorporate"
                                  [passengerKey]="psgKey"
                                  (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchValue)="onChangeInputValueFromProfile($event, 'name', psgKey)"
                                ></app-search-profiles>
                              </ng-container>
                            </div>
                            <div class="form-group form-group-sm col-md-6"
                                 *ngIf="form.get(psgKey + '.data.surname') as surname">
                              <label><span class="text-primary"><b>*</b></span> Surname</label>
                              <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                <input [validate]="surname.dirty || validateTriggered"
                                       type="text"
                                       formControlName="surname"
                                       class="form-control"
                                       autocomplete="false"
                                       placeholder="Crow"/>
                              </ng-container>
                              <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                <app-search-umbrella-travellers
                                  [isValid]="!surname.invalid && surname.dirty"
                                  [isInvalid]="surname.invalid && surname.dirty"
                                  [travellerInputValue]="this.form.value[psgKey]?.data?.surname"
                                  [isDisabled]="isChangeCompany"
                                  [noInputFocus]="true"
                                  [passengerFormKey]="psgKey"
                                  (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchString)="onChangeInputValue($event, 'surname', psgKey)"
                                ></app-search-umbrella-travellers>
                              </ng-container>
                              <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                <app-search-profiles
                                  [isValid]="!surname.invalid && surname.dirty"
                                  [isInvalid]="surname.invalid && surname.dirty"
                                  [inputValue]="this.form.value[psgKey]?.data?.surname"
                                  [isDisabled]="isChangeCorporate"
                                  [noInputFocus]="true"
                                  [passengerKey]="psgKey"
                                  (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchValue)="onChangeInputValueFromProfile($event, 'surname', psgKey)"
                                ></app-search-profiles>
                              </ng-container>
                            </div>
                          </div>

                          <div class="form-row" formGroupName="data">
                            <div class="form-group form-group-sm col-md-6" *ngIf="form.get(psgKey + '.data.birthdate') as birthdate">
                              <label class="d-flex align-items-center gap-5">
                                Date of birth (YYYY-MM-DD)
                                <i class="material-icons icon info"
                                   [ngbPopover]="'Date can be added by using the specified format or by browsing on calendar'"
                                   popoverClass="info-popover"
                                   [triggers]="'hover'"
                                >
                                  info_outline
                                </i>
                              </label>
                              <div class="datepicker-container">
                                <input
                                  [mask]="'0000-00-00'"
                                  [validate]="birthdate.dirty || validateTriggered"
                                  [minMaxDateOfBirth]="minMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]"
                                  autocomplete="false"
                                  formControlName="birthdate"
                                  [placement]="'bottom'"
                                  ngbDatepicker
                                  [minDate]="minMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]?.minDate"
                                  [maxDate]="minMaxDateOfBirth[(travelerType[0] | travelerTypePipe).toUpperCase()]?.maxDate"
                                  #d="ngbDatepicker"
                                  (click)="d.close()"
                                  class="form-control"
                                  placeholder="YYYY-MM-DD"
                                />
                                <button (click)="d.toggle()">
                                  <i class="material-icons" style='color: white'>
                                    event
                                  </i>
                                </button>
                              </div>
                            </div>
                            <div class="form-group form-group-sm" *ngIf="form.get(psgKey + '.data.title') as title"
                                 [ngClass]="!isGenderExists[psgKey] ? 'col-md-3' : 'col-md-6'">
                              <label for="title"><span class="text-primary"><b>*</b></span> Title</label>
                              <ng-select2
                                [validate]="title.dirty || validateTriggered"
                                formControlName="title"
                                class="ng-select2-form-control d-flex"
                                [placeholder]="'Select title ...'"
                                (click)="helpers.setFocusToSelect2SearchField()"
                                (valueChanged)="onSelectTitle($event, psgKey)"
                                id="title"
                              >
                                <option *ngFor="let availableTitle of titles" [value]="availableTitle">{{ availableTitle | uppercase }}
                              </ng-select2>
                            </div>
                            <ng-container *ngIf="!isGenderExists[psgKey]">
                              <div class="form-group form-group-sm col-md-3" *ngIf="form.get(psgKey + '.data.gender') as gender">
                                <label><span class="text-primary"><b>*</b></span> Gender</label>
                                <div class="radio-container">
                                  <div class="custom-control custom-radio custom-control-inline" *ngFor="let gen of genders">
                                    <input [validate]="gender.dirty || validateTriggered"
                                           type="radio"
                                           [value]="gen"
                                           formControlName="gender"
                                           class="form-control custom-control-input"
                                           id="{{(psgKey + '_' + gen)}}"
                                    >
                                    <label class="custom-control-label" for="{{(psgKey + '_' + gen)}}">{{gen}}</label>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>

                          <div class="form-row" formGroupName="data">
                            <ng-container *ngIf="form.get(psgKey + '.data.email') as email">
                              <div class="form-group col-md-6">
                              <label class="d-flex align-items-center gap-5">
                                <span>
                                  <span class="text-primary" *ngIf="firstTravelerID === psgKey"><b>*</b></span>
                                  Email
                                </span>
                                <i class="material-icons icon info"
                                   *ngIf="firstTravelerID === psgKey"
                                   [ngbPopover]="'The Provider might send information related to the order to this email address'"
                                   popoverClass="info-popover"
                                   [triggers]="'hover'">
                                  info_outline
                                </i>
                              </label>

                              <ng-container *ngIf="(!umbrellaCompanyShow || !(ls.umbrellaToken && ls.profilesType === 'umbrella')) && !corporateShow">
                                <input [validate]="email.dirty || validateTriggered"
                                       type="text"
                                       formControlName="email"
                                       autocomplete="false"
                                       class="form-control"
                                       placeholder="web@example.com"/>
                              </ng-container>
                              <ng-container *ngIf="umbrellaCompanyShow && ls.umbrellaToken && ls.profilesType === 'umbrella'">
                                <app-search-umbrella-travellers
                                  [isValid]="!email.invalid && email.dirty"
                                  [isInvalid]="email.invalid && email.dirty"
                                  [travellerInputValue]="this.form.value[psgKey]?.data?.email"
                                  [isDisabled]="isChangeCompany"
                                  [target]="'email'"
                                  [noInputFocus]="true"
                                  [passengerFormKey]="psgKey"
                                  (emitPsgFormData)="onEmitUmbrellaPsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchString)="onChangeInputValue($event, 'email', psgKey)"
                                ></app-search-umbrella-travellers>
                              </ng-container>
                              <ng-container *ngIf="corporateShow && ls.profilesType === 'airgateway'">
                                <app-search-profiles
                                  [isValid]="!email.invalid && email.dirty"
                                  [isInvalid]="email.invalid && email.dirty"
                                  [inputValue]="this.form.value[psgKey]?.data?.email"
                                  [isDisabled]="isChangeCorporate"
                                  [target]="'email'"
                                  [noInputFocus]="true"
                                  [passengerKey]="psgKey"
                                  (emitProfileData)="onEmitProfilePsgFormData($event, travelerType[0].toUpperCase())"
                                  (emitSearchValue)="onChangeInputValueFromProfile($event, 'email', psgKey)"
                                ></app-search-profiles>
                              </ng-container>
                            </div>
                            </ng-container>
                            <div class="form-group col-md-6" formGroupName="phone">
                              <label><span class="text-primary" *ngIf="firstTravelerID === psgKey"><b>*</b></span> Phone</label>
                              <input
                                [validate]="form.get(psgKey + '.data.phone.number').dirty || validateTriggered"
                                (countryChange)="onCountryChange(psgKey, $event)"
                                (intlTelInputObject)="telInputObject(psgKey, $event)"
                                (ng2TelOutput)="getNumber($event)"
                                [ng2TelInputOptions]="helpers.defaultTelInputOptions"
                                class="form-control"
                                formControlName="number"
                                ng2TelInput>
                            </div>
                          </div>

                          <div formGroupName="data" *ngIf="travelerType[0].toUpperCase() !== 'INF'">
                            <label>Frequent flyer number details</label>
                            <div formGroupName="fqtvInfo" *ngIf="form.get(psgKey + '.data.fqtvInfo') as fqtvInfoControl">
                              <div class="form-row">
                                <div class="form-group col-md-6 selection">
                                  <ng-select2
                                    [validate]="fqtvInfoControl.get('airlineID').dirty ||
                                                  fqtvInfoControl.get('account.number').dirty || validateTriggered"
                                    class="ng-select2-form-control select-border"
                                    formControlName="airlineID"
                                    [placeholder]="'Select Airline Designator ...'"
                                    (click)="helpers.setFocusToSelect2SearchField()"
                                  >
                                    <option *ngFor="let c of (dictionaryAirlineDesignators | pairs)" [value]="c[0]">{{ c[1] }}
                                  </ng-select2>
                                  <i class="material-icons close-icon"
                                     [class.airline-id-valid]="fqtvInfoControl.get('airlineID').valid && (fqtvInfoControl.get('airlineID').dirty ||
                                                                fqtvInfoControl.get('account.number').dirty || validateTriggered)"
                                     [class.airline-id-invalid]="fqtvInfoControl.get('airlineID').invalid"
                                     (click)='resetAirlineDesignator(travelerType, idx)'> close </i>
                                </div>
                                <div class="form-group col-md-6" formGroupName="account" *ngIf="form.get(psgKey + '.data.fqtvInfo.account.number') as number">
                                  <div class="input-group flyer-input-group">
                                    <input [validate]="number.dirty || validateTriggered"
                                           formControlName="number"
                                           placeholder="Frequent Flyer Number"
                                           type="text"
                                           class="form-control"/>
                                    <!-- <div class="input-group-append umbrella-icon-wp"
                                         *ngIf="currentTravellerFromUmbrella">
                                      <span
                                        ngbTooltip="Select from available numbers in umbrella"
                                        placement="left"
                                        (click)="open(flyerNumberSelectModal, 'lg', 'flyer-popup-main')"
                                        class="input-group-text umbrella-icon"
                                        >&#9730;
                                      </span>
                                    </div> -->
                                    <!-- <div class="input-group-append umbrella-icon-wp"
                                         *ngIf="currentTravellerFromProfile">
                                      <span
                                        ngbTooltip="Select from available numbers in profiles"
                                        placement="left"
                                        (click)="open(flyerNumberSelectModal, 'lg', 'flyer-popup-main')"
                                        class="input-group-text umbrella-icon">&#9730;
                                      </span>
                                    </div> -->
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                          <ng-container formGroupName="data" *ngIf="checkIsFirstPassenger(psgKey) || corporateShow">
                            <div class="form-row">
                              <div class="form-group col-md-3">
                                <ng-select2
                                  [placeholder]="'Add address'"
                                  (click)="helpers.setFocusToSelect2SearchField()"
                                  formControlName="addressType"
                                  class="ng-select2-control address-select"
                                  (valueChanged)="addAddress($event, psgKey)"
                                >
                                  <option value="">Add address</option>
                                  <option value="home" *ngIf="!addresses.home">Home</option>
                                  <option value="destination">Destination</option>
                                </ng-select2>
                              </div>
                            </div>
                            <ng-container *ngIf="addresses.home">
                              <div class="address mb-2">
                                Home address
                              </div>
                              <div class="form-row" formGroupName="address">
                                <div class="form-group col-md-3">
                                  <label>Country</label>
                                  <ng-select2
                                    formControlName="countryCode"
                                    [validate]="form.get(psgKey + '.data.address.countryCode').dirty || validateTriggered"
                                    class="ng-select2-form-control"
                                    [placeholder]="'Select country ...'"
                                    (click)="helpers.setFocusToSelect2SearchField()"
                                  >
                                    <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                    </option>
                                  </ng-select2>
                                </div>
                                <div class="form-group col-md-3">
                                  <label>City</label>
                                  <input
                                    type="text"
                                    formControlName="cityName"
                                    [validate]="form.get(psgKey + '.data.address.cityName').dirty || validateTriggered"
                                    placeholder="Madrid"
                                    autocomplete="false"
                                    class="form-control">
                                </div>
                                <div class="form-group col-md-2">
                                  <label>Postal code</label>
                                  <input
                                    type="text"
                                    formControlName="postalCode"
                                    [validate]="form.get(psgKey + '.data.address.postalCode').dirty || validateTriggered"
                                    placeholder="28001"
                                    autocomplete="false"
                                    class="form-control ">
                                </div>
                                <div class="form-group col-md-4">
                                  <label>Street</label>
                                  <div class="form-row">
                                    <div class="col">
                                      <input
                                        type="text"
                                        formControlName="street"
                                        [validate]="form.get(psgKey + '.data.address.street').dirty || validateTriggered"
                                        placeholder="Gran Via"
                                        autocomplete="false"
                                        class="form-control">
                                    </div>
                                    <div class="col">
                                      <button class="btn btn-secondary" (click)="removeAddress('home', psgKey + '.data.address')">
                                        remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <ng-container formGroupName="data" *ngIf="addresses.destination">
                            <div formGroupName="addresses">
                              <div class="address mb-2">
                                Destination address
                              </div>
                              <ng-container *ngFor="let control of form.get(psgKey + '.data.addresses')['controls']; let idx = index" [formGroupName]="idx">
                                <div class="form-row">
                                  <div class="form-group col-md-3">
                                    <label>Country</label>
                                    <ng-select2
                                      formControlName="countryCode"
                                      class="ng-select2-form-control"
                                      [placeholder]="'Select country ...'"
                                      (click)="helpers.setFocusToSelect2SearchField()"
                                    >
                                      <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                      </option>
                                    </ng-select2>
                                  </div>
                                  <div class="form-group col-md-3">
                                    <label>City</label>
                                    <input
                                      type="text"
                                      formControlName="cityName"
                                      placeholder="Madrid"
                                      autocomplete="false"
                                      class="form-control">
                                  </div>
                                  <div class="form-group col-md-2">
                                    <label>Postal code</label>
                                    <input
                                      type="text"
                                      formControlName="postalCode"
                                      placeholder="28001"
                                      autocomplete="false"
                                      class="form-control ">
                                  </div>
                                  <div class="form-group col-md-4">
                                    <label>Street</label>
                                    <div class="form-row">
                                      <div class="col">
                                        <input
                                          type="text"
                                          formControlName="street"
                                          placeholder="Gran Via"
                                          autocomplete="false"
                                          class="form-control">
                                      </div>
                                      <div class="col">
                                        <button class="btn btn-secondary" (click)="removeAddress('destination', psgKey + '.data.addresses', idx)">
                                          remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="(offer.passengers | excludePassengersPipe: true : 'ADT') as adultPassengers">
                            <div class="form-row" *ngIf="adultPassengers.length > 1 && travelerType[0].toUpperCase() === 'INF'">
                              <div class="form-group-sm col-md-6" *ngIf="form.get(psgKey + '.adultReference') as adultReference">
                                <label><span class="text-primary"><b>*</b></span> Adult reference</label>
                                <select formControlName="adultReference" required class="form-control" (change)="onAdultReferenceChange($event.target.value, idx, psgKey)">
                                  <option value="" hidden>Select adult reference...</option>
                                  <option value="clear" [hidden]="!adultReference.value">Clear reference</option>
                                  <option *ngFor="let passenger of adultPassengers; let idx = index"
                                          [value]="passenger.travelerReference"
                                          [hidden]="adultReferences.includes(passenger.travelerReference)">
                                    (ADT {{idx + 1}}) {{form.get('passenger_adt_' + idx + '.data.name')?.value}} {{form.get('passenger_adt_' + idx + '.data.surname')?.value}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </ng-container>

                          <div formGroupName="documents">
                            <div class="documents gap-5 mb-2"
                                 [class.mb-4]="form.get(psgKey + '.documents')['controls'].length">
                              <span placement="top" ngbTooltip="These fields are mandatory in some countries and airlines">
                                Documents
                              </span>
                              <i *ngIf="isSecureFlight"
                                 class="material-icons icon info"
                                 [ngbPopover]="'This is a secure flight. Passport/National ID might be required by the provider before issuing. Please keep in mind we don\'t support passenger updates with all providers.'"
                                 popoverClass="info-popover"
                                 [triggers]="'hover'"
                              >
                                info_outline
                              </i>
                            </div>
                            <div class="document-outer" *ngFor="let control of form.get(psgKey + '.documents')['controls']; let idx = index; first as first" [formGroupName]="idx">
                              <div *ngIf="!requireDocumentId || !first" class="col-auto remove-document-btn">
                                <i (click)="removeDocument(psgKey + '.documents', idx)"
                                   class="material-icons float-right ml-3"
                                >close</i>
                              </div>
                              <div class="form-row">
                                <div
                                  *ngIf="control.get('documentType') as identityDocumentType"
                                  class="form-group-sm col-md-4">
                                  <label ngbTooltip="Some countries need Fiscal ID to be added to sell in some countries like Ecuador or Perú">
                                    <span class="text-primary"><b>*</b></span> Document Type
                                  </label>
                                  <ng-select2
                                    [validate]="identityDocumentType.dirty || validateTriggered"
                                    formControlName="documentType"
                                    class="ng-select2-form-control"
                                    [placeholder]="'Select Document Type...'"
                                    (click)="helpers.setFocusToSelect2SearchField()"
                                  >
                                    <option *ngFor="let type of (documentTypeOptions | pairs)" [value]="type[0]">{{ type[1] }}
                                  </ng-select2>
                                </div>
                                <ng-container>
                                <div
                                  *ngIf="control.get('documentID') as identityDocumentID"
                                  class="form-group-sm col-md-4">
                                  <label ngbTooltip="Iberia's Fiscal ID need this format: RUC/12345789/COMPANY">
                                    <span class="text-primary"><b>*</b></span> Document ID
                                  </label>
                                  <input [validate]="identityDocumentID.dirty || validateTriggered"
                                         type="text"
                                         formControlName="documentID"
                                         autocomplete="false"
                                         class="form-control"
                                         placeholder="XXXXXXX"/>
                                </div>
                                </ng-container>
                                <ng-container>
                                <div class="form-group-sm col-md-4"
                                     *ngIf="control.get('expirationDate') as expirationDate">
                                  <label class="datepicker-label">
                                    Expiration date
                                  </label>
                                  <div class="datepicker-container">
                                    <input
                                      [mask]="'0000-00-00'"
                                      [validate]="expirationDate.dirty || validateTriggered"
                                      autocomplete="false"
                                      [placement]="'bottom'"
                                      ngbDatepicker
                                      #t="ngbDatepicker"
                                      (click)="t.close()"
                                      class="form-control"
                                      placeholder="YYYY-MM-DD"
                                      formControlName="expirationDate"
                                      [minDate]="minMaxDocumentExpirationDate.minDate"
                                    />
                                    <button (click)="t.toggle()">
                                      <i class="material-icons" style='color: white'>
                                        event
                                      </i>
                                    </button>
                                  </div>
                                </div>
                                </ng-container>
                              </div>
                              <div class="form-row">
                                <div *ngIf="control.get('fiscalName') as fiscalName"
                                     class="form-group-sm col-md-3">
                                  <label>Fiscal Name</label>
                                  <input
                                    [validate]="fiscalName.dirty || validateTriggered"
                                    type="text"
                                    formControlName="fiscalName"
                                    placeholder="XXXXXXX"
                                    autocomplete="false"
                                    class="form-control">
                                </div>
                              <div
                                *ngIf="control.get('issuingCountryCode') as issuingCountryCode"
                                class="form-group-sm col-md-3">
                                <label>Issuing Country Code</label>
                                <ng-select2
                                  [validate]="issuingCountryCode.dirty || validateTriggered"
                                  formControlName="issuingCountryCode"
                                  class="ng-select2-form-control"
                                  [placeholder]="'Select Issuing Country Code...'"
                                  (click)="helpers.setFocusToSelect2SearchField()"
                                >
                                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                </ng-select2>
                              </div>
                              <div
                                *ngIf="control.get('citizenshipCountryCode') as citizenshipCountryCode"
                                class="form-group-sm col-md-3">
                                <label>Citizenship Country Code</label>
                                <ng-select2
                                  [validate]="citizenshipCountryCode.dirty || validateTriggered"
                                  formControlName="citizenshipCountryCode"
                                  class="ng-select2-form-control"
                                  [placeholder]="'Select Citizenship Country Code...'"
                                  (click)="helpers.setFocusToSelect2SearchField()"
                                >
                                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                </ng-select2>
                              </div>
                              <div
                                *ngIf="control.get('residenceCountryCode') as residenceCountryCode"
                                class="form-group-sm col-md-3">
                                <label>Residence Country Code</label>
                                <ng-select2
                                  [validate]="residenceCountryCode.dirty || validateTriggered"
                                  formControlName="residenceCountryCode"
                                  class="ng-select2-form-control"
                                  [placeholder]="'Select Residence Country Code...'"
                                  (click)="helpers.setFocusToSelect2SearchField()"
                                >
                                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                                </ng-select2>
                              </div>
                            </div>
                            </div>
                            <div class="mb-4">
                              <button class="btn btn-outline-primary"
                                      (click)="addDocument(psgKey)"
                              >Add document</button>
                            </div>
                          </div>
                          <div *ngIf="showDiscounts">
                            <div class="form-row">
                              <div class="col-md-12" *ngIf="offer.discounts.residentCode">
                                <div class="show-special-discounts mt-3 mb-2">
                                  <span>Residence Discount Iberia</span>
                                </div>
                                <div class="row form-group" formGroupName="residenceDiscountIberia">
                                  <div class="col-md-6">
                                    <label><span class="text-primary"><b>*</b></span> Identity Document Number</label>
                                    <input type="text"
                                           class="form-control"
                                           formControlName="documentIdResident"
                                           placeholder="Identity Document Number"
                                           autocomplete="false"
                                           [validate]="validateTriggered">
                                  </div>
                                  <div class="col-md-4">
                                    <label><span class="text-primary"><b>*</b></span> Code of Location</label>
                                    <ng-select2
                                      formControlName="documentTypeResident"
                                      class="ng-select2-form-control"
                                      [validate]="validateTriggered"
                                      [placeholder]="'Select code ...'"
                                      (click)="helpers.setFocusToSelect2SearchField()"
                                    >
                                      <option *ngFor="let discount of (residentDiscounts | pairs)" [value]="discount[0]">{{ discount[0] }} {{ discount[1] }}
                                      </option>
                                    </ng-select2>
                                  </div>

                                  <div class="col-md-2">
                                    <label>Resident in:</label>
                                    <div class="btn-group dropdown d-block">
                                      <button class="btn btn-light">
                                        {{iberiaDiscounts[psgKey].residentCode.identityDocumentType !== '' ? iberiaDiscounts[psgKey].residentCode.identityDocumentType : 'None' }}
                                      </button>
                                    </div>
                                  </div>
<!--                                  <div class="col-md-2">-->
<!--                                    <label>Resident in:</label>-->
<!--                                    <div class="btn-group dropdown d-block" ngbDropdown-->
<!--                                         #residentTypeDropdown="ngbDropdown">-->
<!--                                      <button class="btn btn-light" aria-haspopup="true" aria-expanded="false"-->
<!--                                              ngbDropdownToggle>-->
<!--                                        {{iberiaDiscounts[psgKey].residentCode.identityDocumentType !== '' ? iberiaDiscounts[psgKey].residentCode.identityDocumentType : 'None' }}-->
<!--                                      </button>-->
<!--                                      <div ngbDropdownMenu>-->
<!--                                        <a-->
<!--                                          *ngFor="let item of (residentsList | keyvalue)"-->
<!--                                          (click)="iberiaDiscounts[psgKey].residentCode.identityDocumentType = item.key; residentTypeDropdown.close();"-->
<!--                                          class="dropdown-item inversed"-->
<!--                                        >{{ item.value }}</a>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
                                </div>
                              </div>


                              <div class="form-group col-md-12" *ngIf="offer.discounts.largeFamily">
                                <div class="show-special-discounts mt-3 mb-2">
                                  <span>Large Family Discount Iberia</span>
                                </div>
                                <div class="row form-group" formGroupName="largeFamilyDiscountIberia">
                                  <div class="col-md-6">
                                    <label><span class="text-primary"><b>*</b></span> Identity Document Number</label>
                                    <input type="text"
                                           class="form-control"
                                           formControlName="documentIdFamily"
                                           placeholder="Identity Document Number"
                                           autocomplete="false"
                                           [validate]="validateTriggered">
                                    <div class="invalid-feedback">
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <label><span class="text-primary"><b>*</b></span> Code of Community</label>
                                    <input type="text"
                                           class="form-control"
                                           formControlName="documentTypeFamily"
                                           placeholder="Code of Community"
                                           autocomplete="false"
                                           [validate]="validateTriggered">
                                    <div class="invalid-feedback">
                                    </div>
                                  </div>

                                  <div class="col-md-2">
                                    <label>Large family:</label>
                                    <div class="btn-group dropdown d-block">
                                      <button class="btn btn-light">
                                        {{largeFamilyList[iberiaDiscounts[psgKey].largeFamily.identityDocumentType]}}
                                      </button>
                                    </div>
                                  </div>
<!--                                  <div class="col-md-2">-->
<!--                                    <label>Large family:</label>-->
<!--                                    <div class="btn-group dropdown d-block" ngbDropdown-->
<!--                                         #largeFamilyTypeDropdown="ngbDropdown">-->
<!--                                      <button class="btn btn-light" aria-haspopup="true" aria-expanded="false"-->
<!--                                              ngbDropdownToggle>-->
<!--                                        {{largeFamilyList[iberiaDiscounts[psgKey].largeFamily.identityDocumentType]}}-->
<!--                                      </button>-->
<!--                                      <div ngbDropdownMenu>-->
<!--                                        <a-->
<!--                                          *ngFor="let familyType of (largeFamilyList | pairs);"-->
<!--                                          (click)="iberiaDiscounts[psgKey].largeFamily.identityDocumentType = familyType[0]; largeFamilyTypeDropdown.close();"-->
<!--                                          class="dropdown-item inversed"-->
<!--                                        >{{ familyType[1] }}</a>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-row">
                            <div class="mx-auto" [class.form-group]="offer.allowedExtraFields?.otherServiceInformation || ssrSelectedCount[ssrPassengerID]">
                              <span class="text-primary"><b>*</b></span> Required fields
                            </div>
                          </div>

                          <div class="form-row" *ngIf="offer.specialServices && offer.specialServices.length && ssrSelectedCount[ssrPassengerID]">
                            <div class="form-group col-md-12">
                              <table class="table table-default special-service-table mb-0">
                                <thead>
                                <tr>
                                  <th>Description</th>
                                  <th>Comment</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let service of offer.specialServicesWithoutIncluded">
                                  <ng-container *ngFor="let flight of offer.flights">
                                    <ng-container *ngFor="let fs of flight.segments">
                                      <tr *ngIf="ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID]">
                                        <td class="descr">{{service.desc}}</td>
                                        <td>
                                          <span
                                            *ngIf="service.bookingInstructions.mandatoryText !== 'not_allowed'"
                                          > {{ ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID] ? ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID] : '(not set)'}}</span>
                                          <span *ngIf="service.bookingInstructions.mandatoryText === 'not_allowed'">-</span>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ngb-tab>
                  </ng-container>
                </ng-container>
              </ngb-tabset>

              <div class="osi" *ngIf="offer.allowedExtraFields?.otherServiceInformation">
                <hr>
                <h3>OSI</h3>
                <ng-container formGroupName="osi">
                  <ng-container *ngFor="let control of form.get('osi')['controls']; let osiIdx = index; first as first" [formGroupName]="osiIdx">
                    <div class="d-flex align-items-center gap-15 mb-3">
                      <div class="fs-16">
                        OSI {{osiIdx + 1}}
                      </div>
                      <i class="material-symbols-outlined remove-icon fs-18"
                         title="Remove"
                         (click)="removeOSI(osiIdx)">
                        delete
                      </i>
                    </div>
                    <div class="form-row">
                      <div class="form-group-sm col-md-2">
                        <label>OSI Code</label>
                        <input
                          type="text"
                          formControlName="osiCode"
                          autocomplete="false"
                          placeholder="Enter OSI Code"
                          class="form-control">
                      </div>

                      <div class="form-group-sm col-md-2">
                        <label>Action Code</label>
                        <input
                          type="text"
                          formControlName="actionCode"
                          autocomplete="false"
                          placeholder="Enter Action Code"
                          class="form-control">
                      </div>

                      <div class="form-group-sm col-md-8">
                        <label>Text</label>
                        <input
                          type="text"
                          formControlName="text"
                          autocomplete="false"
                          placeholder="Free text..."
                          class="form-control">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col">
                        <label>Apply to:</label>
                        <div class="form-row">
                          <div class="custom-col form-group-sm">
                            <div class="form-control">
                              <label class="checkbox custom-checkbox-1 mb-0">
                                <input type="checkbox"
                                       [checked]="applyOsiByPassengerTypeDisabled[osiIdx]"
                                       (change)="onPassengerRefChange(control, $event.target.checked, osiIdx, -1)"/>
                                <div class="checkbox__checkmark"></div>
                                <div class="checkbox__body fs-15">
                                  All
                                </div>
                              </label>
                            </div>
                          </div>
                          <div class="vr mx-2 form-group-sm"></div>
                          <ng-container *ngFor="let travelerType of (travelers | pairs); let travTypeIdx = index">
                            <ng-container *ngFor="let travIdx of seatMapService.createRange(travelerType[1]);">
                              <div class="custom-col form-group-sm">
                                <div class="form-control" [class.disabled]="applyOsiByPassengerTypeDisabled[osiIdx]">
                                  <label class="checkbox custom-checkbox-1 mb-0">
                                    <input type="checkbox"
                                           [checked]="selectedPassengersPerOsi[osiIdx] && selectedPassengersPerOsi[osiIdx][travTypeIdx] && selectedPassengersPerOsi[osiIdx][travTypeIdx][travIdx] && !applyOsiByPassengerTypeDisabled[osiIdx]"
                                           (change)="onPassengerRefChange(control, $event.target.checked, osiIdx, travIdx, travTypeIdx, travelerType[0])"/>
                                    <div class="checkbox__checkmark"></div>
                                    <div class="checkbox__body fs-15">
                                      {{travelerType[0].toUpperCase() + ' ' +  (travIdx + 1)}}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="form-row">
                  <div class="col form-group-sm">
                    <button class="btn btn-tonal-primary"
                            title="Add OSI"
                            (click)="addOSI()">
                      <i class='fa fa-plus w-10 fs-10'></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.RESERVATION">
              <app-offer-price-view
                [offer]="offer"
                [fareRules]="fareRules"
                [travelers]="travelers"
                [isShowDisclosureTable]="true"
                [useNewDesign]="true"
              ></app-offer-price-view>

              <div class="col pl-2 pr-2">
                <div class="col-lg-12">
                  <div class="mb-3" *ngFor="let p of passengersInfo; let i = index">
                    <h3 class="h3-label">{{ psgTitles[i] }}</h3>
                    <p *ngIf="p.data.title && p.data.name && p.data.surname;else nameNotSet">
                      {{ p.data.title }} {{ p.data.name }} {{ p.data.surname }}
                    </p>
                    <ng-template #nameNotSet>
                      <p>Name: (not set)</p>
                    </ng-template>
                    <ng-container
                      *ngIf="p.specialServices && p.specialServices.length > 0 || includedSpecialServices && includedSpecialServices[p.data.passengerType]?.length">
                      <h5>Special Services for {{ psgTitles[i] }}</h5>
                      <ng-container
                        *ngIf="includedSpecialServices && includedSpecialServices[p.data.passengerType]?.length">
                        <p *ngFor="let ssr of includedSpecialServices[p.data.passengerType]">{{ ssr.desc }} in
                          <b *ngFor="let seg of ssr.segmentIDs">{{ segment2FlightRouteMapping[seg] }}</b>
                        </p>
                      </ng-container>
                      <p *ngFor="let ssr of p.specialServices">{{ ssrCode2DescriptionMapping[ssr.code] }} in
                        <b *ngFor="let seg of ssr.segmentIDs">{{ segment2FlightRouteMapping[seg] }}</b>
                      </p>
                    </ng-container>
                    <!--
                    <div *ngIf="p.data.birthdate;else birthdateNotSet">Birthdate: {{ p.data.birthdate }}</div>
                    <ng-template #birthdateNotSet>
                      <div>Birthdate: (not set)</div>
                    </ng-template>

                    <div *ngIf="p.data.email;else emailNotSet">Email: {{ p.data.email }}</div>
                    <ng-template #emailNotSet>
                      <div>Email: (not set)</div>
                    </ng-template>

                    <div *ngIf="p.data.phone.countryCode && p.data.phone.number;else phoneNotSet">
                      Phone: +{{ p.data.phone.countryCode}}{{ p.data.phone.number}}</div>
                    <ng-template #phoneNotSet>
                      <div>Phone: (not set)</div>
                    </ng-template>

                    <div *ngIf="p.data.gender;else genderNotSet">Gender: {{ p.data.gender}}</div>
                    <ng-template #genderNotSet>
                      <div>Gender: (not set)</div>
                    </ng-template>

                    <div *ngIf="p.data.address.countryCode && p.data.address.postalCode && p.data.address.street;else addressNotSet">
                      Address: {{ p.data.address.countryCode }} {{ p.data.address.postalCode }} {{ p.data.address.street }}</div>
                    <ng-template #addressNotSet>
                      <div>Address: (not set)</div>
                    </ng-template>

                    <div *ngIf="p.document.documentID && p.document.documentType;else documentNotSet">Document: {{ p.document.documentID}} {{ p.document.documentType}}</div>
                    <ng-template #documentNotSet>
                      <div>Document: (not set)</div>
                    </ng-template>
                    -->
                  </div>
                </div>


                <ng-container *ngIf="!!showSeats">
                  <div class="row pl-3 pr-2 mt-2">
                    <div class="col-lg-12 mt-2">
                      <h4 class="seats-h4">Seats</h4>
                      <ng-container *ngFor="let segmentSeats of (seatsPerSegment | pairs)">
                        <div *ngIf="segmentSeats[1]?.name"><span class="segment-name">{{ segmentSeats[1].name }}:</span>
                          <ul class="seats-ul">
                            <li *ngFor="let seat of segmentSeats[1].seats">
                              <span class="seat-number">{{seat.location.column + seat.location.row}}</span>
                              - <span
                              class="seat-price">{{seat?.price?.consumer.total | number :'1.2-2'}} {{seat?.price?.consumer.currency}}</span>
<!--                              <span class="flight-number"> ({{seat.segment}})</span>-->
                              <a href="#"
                                 class="material-icons seat-remove-icon my-auto"
                                 (click)="$event.preventDefault(); removeSeat(segmentSeats[0], seat)"
                              >close</a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="selectedServices?.length > 0">
                  <div class="col-12 pl-2 pr-2 mt-4">
                    <h3 class="h3-label">Services</h3>
                    <ng-container *ngIf="servicePerSegment.entireTrip?.length > 0">
                      <div class="segment-container">
                        <div class="destination-type">
                          <div class="services-h3">Entire Trip</div>
                        </div>
                        <div class="service-info">
                          <ul class="services-ul">
                            <li *ngFor="let srv of servicePerSegment.entireTrip; let idx = index">
                              <span class="services-number">{{ srv.name }}</span>
                              - <span
                              class="services-price">{{srv?.price?.consumer.total | number :'1.2-2'}} {{srv?.price?.consumer.currency}}</span>
                              <span class="flight-number"> traveler: {{ passengerNameByRef(srv) }}</span>
                              <a href="#"
                                 class="material-icons seat-remove-icon my-auto"
                                 (click)="$event.preventDefault(); removeService(srv.serviceID)"
                              >close</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container  *ngIf="(servicePerSegment.perLeg | keyvalue)?.length > 0">
                      <div class="segment-container">
                        <div class="destination-type">
                          <div class="services-h3">Per Leg</div>
                        </div>
                        <div class="service-info">
                          <ng-container *ngFor="let segmentServices of (servicePerSegment.perLeg | keyvalue) | sortFlightsByRoutes: offer.flights">
                            <div *ngIf="segmentServices?.key"><span class="segment-name">{{ segmentServices.key?.replace('-', ' → ') }}:</span>
                              <ul class="services-ul">
                                <li *ngFor="let srv of segmentServices.value; let idx = index">
                                  <span class="services-number">{{ srv.name }}</span>
                                  - <span
                                  class="services-price">{{srv?.price?.consumer.total | number :'1.2-2'}} {{srv?.price?.consumer.currency}}</span>
                                  <span class="flight-number"> traveler: {{ passengerNameByRef(srv) }}</span>
                                  <a href="#"
                                     class="material-icons seat-remove-icon my-auto"
                                     (click)="$event.preventDefault(); removeService(srv.serviceID)"
                                  >close</a>
                                </li>
                              </ul>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="(servicePerSegment.perSegment | keyvalue)?.length > 0">
                      <div class="segment-container">
                        <div class="destination-type">
                          <div class="services-h3">Per Segments</div>
                        </div>
                        <div class="service-info">
                          <ng-container *ngFor="let segmentServices of (servicePerSegment.perSegment | keyvalue) | sortFlightsByRoutes: segments: false">
                              <div *ngIf="segmentServices?.key"><span class="segment-name">{{ segmentServices.key?.replace('-', ' → ') }}:</span>
                                <ul class="services-ul">
                                  <li *ngFor="let srv of segmentServices.value; let idx = index">
                                    <span class="services-number">{{ srv.name }}</span>
                                    - <span
                                    class="services-price">{{srv?.price?.consumer.total | number :'1.2-2'}} {{srv?.price?.consumer.currency}}</span>
                                    <span class="flight-number"> traveler: {{ passengerNameByRef(srv) }}</span>
                                    <a href="#"
                                       class="material-icons seat-remove-icon my-auto"
                                       (click)="$event.preventDefault(); removeService(srv.serviceID)"
                                    >close</a>
                                  </li>
                                </ul>
                              </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
<!--                <ng-container *ngIf="this.selectedServices.length">-->
<!--                  <div class="col-12 pl-2 pr-2 mt-2">-->
<!--                    <h4 class="services-h4">Services</h4>-->
<!--                    <ng-container *ngFor="let segmentServices of servicePerSegment">-->
<!--                      <div *ngIf="segmentServices?.name"><span class="segment-name">{{ segmentServices.name }}:</span>-->
<!--                        <ul class="services-ul">-->
<!--                          <li *ngFor="let srv of segmentServices.services; let idx = index">-->
<!--                            <span class="services-number">{{ srv.name }}</span>-->
<!--                            - <span-->
<!--                            class="services-price">{{srv?.price?.consumer.total | number :'1.2-2'}} {{srv?.price?.consumer.currency}}</span>-->
<!--                            <span class="flight-number"> traveler: {{ passengerNameByRef(srv) }}</span>-->
<!--                            <a href="#"-->
<!--                               class="material-icons seat-remove-icon my-auto"-->
<!--                               (click)="$event.preventDefault(); removeService(idx)"-->
<!--                            >close</a>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </ng-container>-->
<!--                  </div>-->
<!--                </ng-container>-->

                <div class="col-lg-12 mt-4 mb-3">
                  <h3 class="h3-label">Payment</h3>
                  <p>
                    Method: {{ getPaymentType() }}
                    {{ getPaymentType() === 'none' && paymentSettings.none ? '(On-Hold)' : '' }}

                    <button *ngIf="orderCreateWithPaymentEnabled"
                            class="btn btn-sm btn-outline-primary ml-1"
                            (click)="$event.preventDefault(); changePayment()">
                      Change
                    </button>
                  </p>
                  <p *ngIf="offer.paymentTimeLimit">Payment expires
                    in
                    <app-date-with-popover [date]="offer.paymentTimeLimit">
                    </app-date-with-popover>
                  </p>
                  <p *ngIf="offer.owner !== 'VY'">
                    Setting this field to 'None' will result in an attempt of On-hold reservation
                  </p>
                </div>

                <div class="col-lg-12 mt-4" *ngIf="totalPrice">
                  <h3 class="total-price mt-0">Total Price: {{ totalPrice | number :'1.2-2'}} {{ offer.price.consumer.currency }}</h3>
                  <h4 *ngIf="surchargesInfo">Card Surcharges: {{ surchargesInfo }}</h4>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div
                    *ngIf="orderCreateSuccess && !bookingHasError"
                    class="alert-success p-3 rounded">
                    {{ orderCreateSuccess }}
                  </div>
                  <div
                    *ngIf="orderCreateError && bookingHasError"
                    class="alert-danger p-3 rounded">
                    {{ orderCreateError }}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="card-footer" [ngSwitch]="selectedStep">
            <ng-container *ngSwitchCase="bookingSteps.OFFER">
              <div class="row">
                <div class="col-4 text-left">
                </div>
                <div class="col-4 font-weight-bold d-flex flex-column justify-content-center align-items-center">
                  <ng-container [ngTemplateOutlet]="offerExpiration"></ng-container>
                  <div class="text-allowed" *ngIf="paymentSettings.none">Order on-hold allowed</div>
                  <div class="text-disallowed" *ngIf="!paymentSettings.none">Order on-hold NOT allowed</div>
                </div>
                <div class="col-4 text-right">
                  <span (click)="stepSelect(bookingSteps.PAX)" class="btn btn-primary">Start Booking</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.PAX">
              <div class="row">
                <div class="col-4 text-left"></div>
                <div class="col-4 font-weight-bold d-flex flex-column justify-content-center align-items-center">
                  <ng-container [ngTemplateOutlet]="offerExpiration"></ng-container>
                  <div class="text-allowed" *ngIf="paymentSettings.none">Order on-hold allowed</div>
                  <div class="text-disallowed" *ngIf="!paymentSettings.none">Order on-hold NOT allowed</div>
                </div>
                <div class="col-4 text-right">
                  <button (click)="stepSelect(hasRemarkTemplates ? bookingSteps.REMARKS : bookingSteps.RESERVATION); passengersInfo = getPassengersInfo();"
                          class="btn btn-primary float-right"
                          [ngbPopover]="form.invalid && errors && (errors | json) != '{}' ? paxIncorrectData : ''"
                          popoverClass="pax-data-popover-wp"
                          [autoClose]="'outside'"
                          placement="top"
                          #popOver="ngbPopover"
                  >Continue</button>
                  <span (click)="stepSelect(bookingSteps.OFFER)" class="btn btn-secondary float-right mr-3">Back</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.REMARKS">
              <div class="row">
                <div class="col-4 text-left">
                </div>
                <div class="col-4 font-weight-bold d-flex justify-content-center align-items-center">
                  <ng-container [ngTemplateOutlet]="offerExpiration"></ng-container>
                </div>
                <div class="col-4 text-right">
                  <div class="float-right">
                    <button (click)="stepSelect(bookingSteps.RESERVATION);"
                            class="btn btn-primary float-right"
                            [ngbPopover]="'Remarks must be filled!'"
                            [autoClose]="'outside'"
                            placement="top">
                      {{ isAgencyRemarksTemplateSelected || isCorporateRemarksTemplateSelected ? 'Save & Continue' : 'Continue'}}
                    </button>
                  </div>
                  <button (click)="stepSelect(bookingSteps.PAX);"
                          class="btn btn-secondary float-right mr-3">
                    Back
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="bookingSteps.RESERVATION">
              <div class="row" *ngIf="!bookingProcess.isProcess; else bookingProcessTemplate">
                <div class="col-5 d-flex align-items-center">
                  <button *ngIf="seatAvailabilityEnabled"
                          (click)="this.loadSeatAvailability(seatsModal);"
                        class="btn btn-outline-primary">Select Seats</button>
                  <button *ngIf="serviceListEnabled"
                          (click)="open(serviceListModal, 'xl')"
                          class="btn btn-outline-primary ml-3"
                  >
                    Select Services
                  </button>

                  <button *ngIf="offer.specialServicesWithoutIncluded?.length"
                    class="btn btn-outline-primary ml-3"
                    (click)="openServices(psgTitles, specialServices)"
                  >
                    SSRs
                  </button>

                  <div *ngIf="!offer.specialServices?.length"
                       [ngbPopover]="noServicesTooltip"
                       [triggers]="'hover'"
                       class="d-inline-flex align-items-center ml-3">
                    <ng-template #noServicesTooltip class='tooltip'>
                      SSRs are not available for this airline!
                    </ng-template>
                    <i class="material-icons red-color">info_outline</i>
                  </div>

                </div>
                <div class="col-3 font-weight-bold d-flex justify-content-center align-items-center">
                  <ng-container [ngTemplateOutlet]="offerExpiration"></ng-container>
                </div>
                <div class="col-4 text-right">
                  <div class='float-right'
                       [ngbPopover]="bookAndIssuePopover"
                       [triggers]="'hover'"
                       [disablePopover]="!isBookAndIssueButtonDisabled">
                    <ng-template #bookAndIssuePopover class='tooltip'>
                      {{getPaymentType() === 'none' ? 'To book and issue select payment method'
                          : 'Remarks template has to be filled before ticketing order!'}}
                    </ng-template>
                    <button (click)="makeOrder()"
                            [disabled]="isBookAndIssueButtonDisabled"
                            class="btn btn-primary float-right">Book & Issue
                    </button>
                  </div>
                  <div class='float-right'
                       [ngbPopover]="bookOnHoldPopover"
                       [triggers]="'hover'"
                       [disablePopover]="getPaymentType() === 'none'">
                    <ng-template #bookOnHoldPopover class='tooltip'>
                      <div *ngIf="checkServicesAdded()">- Booking On-hold with ancillaries is not allowed</div>
                      <div *ngIf="getPaymentType() != 'none'">- To book On-hold change payment type to none</div>
                    </ng-template>
                    <button (click)="makeOrder()"
                            *ngIf="paymentSettings.none"
                            [disabled]="isBookOnHoldButtonDisabled"
                            class="btn btn-primary float-right mr-3">Book On-hold
                    </button>
                  </div>
                  <button (click)="stepSelect(hasRemarkTemplates ? bookingSteps.REMARKS : bookingSteps.PAX)"
                          class="btn btn-secondary float-right mr-3">Back
                  </button>
                </div>
              </div>
              <ng-template #bookingProcessTemplate>
                <app-show-processing [processInfo]="bookingProcess.processTitle"></app-show-processing>
              </ng-template>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #specialServices let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h3 class="card-header-title">Select Special Services</h3>
    <button
      *ngIf="!ssrErrorMessage"
      type="button" class="close m-0 p-0" data-dismiss="modal"
      (click)="d('closed')" aria-hidden="true">×
    </button>
  </div>
  <div class="modal-body">
    <div class="pt-2 pl-2 special-service-wp">
      <ngb-tabset #ssrTabs="ngbTabset" [destroyOnHide]="false" [activeId]="ssrPassengerID" (tabChange)="passengerSelected($event)">
        <ng-container *ngFor="let p of passengersInfo | excludePassengersPipe; let i = index">
          <ngb-tab id="{{ p.passengerID }}"
                   title="{{'(' + p.passengerType + ') ' + p.data.name + ' ' + p.data.surname }}">
            <ng-template ngbTabContent>
              <div class="pt-2 pl-2">
                <ngb-tabset>
                  <ng-container *ngFor="let flight of offer.flights">
                    <ng-container *ngFor="let fs of flight.segments">
                      <ngb-tab
                        title="{{ fs.originDestination.departure.airportCode + ' → ' + fs.originDestination.arrival.airportCode }}">
                        <ng-template ngbTabContent>
                          <div style="max-height: 400px; overflow-y: auto">
                            <h5 class="service-title">Additional services: </h5>
                            <table class="table special-service-table">
                              <thead>
                              <tr>
                                <th>Description</th>
                                <th>Comment</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let service of offer.specialServicesWithoutIncluded">
                                <td class="descr">{{service.desc}}</td>
                                <td>
                                <textarea
                                  *ngIf="ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID] && service.bookingInstructions.mandatoryText !== 'not_allowed'"
                                  [(ngModel)]="ssrSelectedText[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                  [placeholder]="service.bookingInstructions.mandatoryText === 'mandatory' ? 'Comment required' : 'Comment optional'"
                                  (ngModelChange)="changeServiceCommentText(service, fs.segmentID)"
                                  [ngClass]="{'is-invalid' : !!ssrSelectedValidation[ssrPassengerID] && !!ssrSelectedValidation[ssrPassengerID][service.code + '|' +  fs.segmentID] }"
                                  class="form-control service-textarea" rows="3"
                                ></textarea>
                                  <div *ngIf="!!ssrSelectedValidation[ssrPassengerID] && !!ssrSelectedValidation[ssrPassengerID][service.code + '|' +  fs.segmentID]"
                                       class="invalid-feedback">Comment required
                                  </div>
                                  <ng-container *ngIf="!ssrSelected[ssrPassengerID][service.code + '|' +  fs.segmentID] || service.bookingInstructions.mandatoryText === 'not_allowed'">
                                    -
                                  </ng-container>
                                </td>
                                <td>
                               <span
                                 (click)="selectService(service, fs.segmentID)"
                                 [class.btn-outline-primary]="!ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                 [class.btn-primary]="ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID]"
                                 class="btn">{{ssrSelected[ssrPassengerID][service.code + '|' + fs.segmentID] ? 'Selected' : 'Select' }}
                               </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </ng-template>
                      </ngb-tab>
                    </ng-container>
                  </ng-container>
                </ngb-tabset>
              </div>
            </ng-template>
          </ngb-tab>
        </ng-container>
      </ngb-tabset>
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="ssrErrorMessage"
         class="alert-warning rounded ssr-error-message"
    >{{ ssrErrorMessage }}</div>
    <div>
      <button
        type="button"
        class="btn btn-secondary mr-3"
        (click)="d('closed')">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveServices(c)">Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #serviceListModal let-c="close" let-d="dismiss">
  <app-service-list-pre-modal
    [owner]="service.owner"
    [shoppingResponseID]="offer.shoppingResponseID"
    [flights]="offer.flights"
    [passengersData]="passengersInfo"
    [passengersList]="passengers"
    [discounts]="offer.discounts"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitServicesSelected)="onServicesSelected($event)"
  ></app-service-list-pre-modal>
</ng-template>

<ng-template #seatsModal let-c="close" let-d="dismiss">
  <div class="modal-card card seats">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Select Seats</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed'); clearPopupNotifications()"
                  aria-hidden="true">×
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="loader-wrapper" *ngIf="seatMapService.showSeatAvailabilityLoader">
        <app-loader></app-loader>
      </div>

      <div *ngIf="seatAvailabilityResponseWarning.length && !seatMapService.showSeatAvailabilityLoader">
        <div *ngIf="seatAvailabilityResponseWarning.length" class="alert-warning p-3 mb-3 rounded">
          <ul>
            <li *ngFor="let item of seatAvailabilityResponseWarning"
                [innerHTML]="item | htmlToStringParser | replace: '\n':'<br\/>'"
            ></li>
          </ul>
        </div>
      </div>
      <div *ngIf="!seatMapService.showSeatAvailabilityLoader">
        <error-alert-block
          [error]="seatMapService.seatAvailabilityError"
        ></error-alert-block>
      </div>

      <app-seat-map
        *ngIf="(seatMapService.seats | json) != '{}'"
        [passengers]="seatPassengers | sortPassengers"
        [segments]="seatsSegments"
        [seatAvailability]="seatAvailability"
        (seatSelect)="onSeatSelect($event)"
      ></app-seat-map>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-secondary mr-3"
        (click)="d('closed'); clearPopupNotifications()"
      >Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="showSelectedSeats(); c('ok')"
        [disabled]="isSelectSeatsButtonDisable"
      >Save
      </button>
    </div>
  </div>
</ng-template>


<ng-template #paymentFormModal let-c="close" let-d="dismiss">
  <div class="modal-card card payment">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Payment info</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed')" aria-hidden="true">×</button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <app-payment-form
        *ngIf="paymentService.form"
        [totalPrice]="totalPrice"
        [allowedPaymentMethods]="paymentSettings"
        [cardSurchargesMap]="offer.cardSurchargesMap"
        [validateTriggered]="paymentValidateTriggered"
        [currency]="offer.price.consumer.currency"
      ></app-payment-form>

    </div>
    <div class="card-footer">
      <div class="row pl-3">
        <div class="col-9 mb-4 mt-3"></div>
        <div class="col-3 payment-proceed">
          <span (click)="d('closed')" class="btn btn-secondary float-right mr-3">Cancel</span>
          <span (click)="savePayment() ? c('ok') : ''" class="btn btn-primary float-right">Save</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #flyerNumberSelectModal let-c="close" let-d="dismiss">
  <div class="modal-card card flyer-number-wp">

    <h4>Select Frequent flyer number for
      <span
        *ngIf="currentTravellerFromUmbrella">{{currentTravellerFromUmbrella?.responseData?.firstname }} {{currentTravellerFromUmbrella?.responseData?.name}}</span>
      <span
        *ngIf="currentTravellerFromProfile">{{currentTravellerFromProfile?.name }} {{currentTravellerFromProfile?.surname}}</span>
    </h4>

    <div class="flyer-number-list">
      <ng-container *ngIf="currentTravellerFromUmbrella">
        <div class="flyer-number-item"
             *ngFor="let traveler of currentTravellerFromUmbrella.responseData?.data.memberships.flight"
             (click)="onSelectFlyerNumber(currentTravellerFromUmbrella.passengerKey, traveler?.alliance, traveler.memberNumber); d('closed')">
          <div class="airline-designator">{{traveler?.alliance}}</div>
          <div class="flyer-number">{{traveler.memberNumber}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTravellerFromProfile">
        <div class="flyer-number-item"
             *ngFor="let traveler of currentTravellerFromProfile.frequentFlyerNumbers"
             (click)="onSelectFlyerNumber(currentTravellerFromProfile.passengerKey, traveler?.alliance, traveler.memberNumber); d('closed')">
          <div class="airline-designator">{{traveler?.alliance}}</div>
          <div class="flyer-number">{{traveler.memberNumber}}</div>
        </div>
      </ng-container>
    </div>

    <div class="btn-wrapper">
      <span (click)="d('closed')" class="btn btn-primary">Cancel</span>
    </div>
  </div>
</ng-template>

<ng-template #confirmStoreModal let-c="close" let-d="dismiss">
  <div class="modal-card card confirm-store">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Confirm Store Travelers</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed'); this.confirmCallback();"
                  aria-hidden="true">×
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ngb-accordion activeIds="{{ getActiveIDsForChanges(changes) }}">
        <ng-container *ngFor="let trav of (changes | pairs); let idx = index">
          <ngb-panel
            id="{{ 'passenger' + '_' + idx }}"
          >
            <ng-template ngbPanelTitle>
              <h3>Changes
                for {{ form.get(trav[0] + '.data.name').value + ' ' + form.get(trav[0] + '.data.surname').value }} </h3>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-container
                *ngIf="trav[1].length"
              >
                <ng-container [formGroup]="form.get(trav[0])">
                  <div class="form-row" formGroupName="data">
                    <div class="col-md-6">
                      <div class="form-check mt-0">
                        <input class="form-check-input" id="{{ 'st_profile_' + trav[0] + '_' + idx }}"
                               type="checkbox" formControlName="storeProfile">
                        <label class="form-check-label"
                               for="{{ 'st_profile_' + trav[0] + '_' + idx }}">
                          Store Traveler Profile

                        </label>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div [ngClass]="{'disabled': !form.get(trav[0] + '.data.storeProfile').value}">
                  <div *ngFor="let ch of trav[1]">
                    <div *ngIf="isObject(ch.new); else elseBlock">
                      <div class="field" *ngIf="ch.field === 'birthdate' || ch.field === 'expirationDate'; else elseBlock2">
                        <b>{{ labelFor(ch.field) }}</b>:
                        <span class="old">{{valueFor(ch.field, ch.old)}}</span>
                        <i class="material-icons icon">arrow_right_alt</i>
                        <span class="new">{{valueFor(ch.field, ch.new)}}</span>
                      </div>
                      <ng-template #elseBlock2>
                        <div class="field" *ngFor="let ob of (ch.new | pairsImpure);">
                          <div
                            *ngIf="ob[0] !== 'birthdate' &&
                            hasDiff(valueFor(ch.field + '.' + ob[0], ch.old[ob[0]]), valueFor(ch.field + '.' + ob[0], ch.new[ob[0]])) &&
                            labelFor(ch.field + '.' + ob[0])"
                          >
                            <b>{{ labelFor(ch.field + '.' + ob[0]) }}</b>:
                            <span class="old">{{valueFor(ch.field + '.' + ob[0], ch.old[ob[0]])}}</span>
                            <i class="material-icons icon">arrow_right_alt</i>
                            <span class="new">{{valueFor(ch.field + '.' + ob[0], ch.new[ob[0]])}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <ng-template #elseBlock>
                      <div class="field"
                           *ngIf="hasDiff(valueFor(ch.field , ch.old), valueFor(ch.field, ch.new)) && labelFor(ch.field)">
                        <b>{{ labelFor(ch.field) }}</b>:
                        <span class="old">{{valueFor(ch.field, ch.old)}}</span>
                        <i class="material-icons icon">arrow_right_alt</i>
                        <span class="new">{{valueFor(ch.field, ch.new)}}</span>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!trav[1].length">
                <div>No changes</div>
              </ng-container>

            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
    <div class="card-footer">
      <div class="row pl-3 pr-3">
        <div class="col-6 mb-4 mt-3"></div>
        <div class="col-6 mt-3">
          <span *ngIf="!hasStoreProfileEnabled()" (click)="c('ok') ; this.confirmCallback();"
                class="btn btn-primary float-right">Go to order</span>
          <span *ngIf="hasStoreProfileEnabled()" (click)="confirmStoreTravelers() ? c('ok') : ''"
                class="btn btn-primary float-right">Store Selected Travelers</span>
          <span (click)="d('closed'); this.confirmCallback(); " class="btn btn-secondary float-right mr-3">Cancel</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #paxIncorrectData>
  <ng-container *ngFor="let error of errors | keyvalue; first as first">
    <h3 class="popover-header" [class.mt-3]="!first">Missing info in: {{error.key | getPassengerFromControlName}}</h3>
    <ng-container *ngIf="error.key.includes('passenger') && errors[error.key]">
      <ul>
        <ng-container *ngFor="let err of errors[error.key] | keyvalue | sortPassengerControlNames; first as firstNested">
          <li *ngIf="firstNested">
            {{err.key | camelCaseToTitleCase}}
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #offerExpiration>
  <app-offer-expiration [time]="offer.createdAt" [expiresAt]="offer.expiresAt" (emitOfferExpiration)="onOfferExpired($event)"></app-offer-expiration>
</ng-template>
