<div class="modal-card card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">Select Passengers For Split  Order</h3>
      </div>
      <div class="col-auto">
        <i
          data-dismiss="modal"
          aria-hidden="true"
          (click)="emitDismiss.emit(true)"
          class="material-icons float-right ml-3"
        >close</i>
      </div>
    </div>
  </div>
  <div class="card-body modal-card-body-height">
    <ng-container *ngIf="orderSplitError.message">
      <error-alert-block
        [error]="orderSplitError"
      ></error-alert-block>
    </ng-container>
    <div class="table-wrapper">
      <div class="left-passenger-list">
        <div class="list-title">
          <h5 class="enabled-passenger-list">Current Passenger List</h5>
        </div>
        <div class="table-container">
          <table class="passenger-table">
            <thead>
              <tr class="table-head">
                <th class="header-passenger-checkbox"></th>
                <th class="header-passenger-name">Name</th>
                <th>Passenger Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                [ngClass] = "{'row-checked': checkbox.checked}"
                class="table-body-row"
                *ngFor="let traveler of leftSideTravelers | removeInfantTravelersPipe"
                (click) = "checkbox.checked = !checkbox.checked; onChangeLeftTransitList(traveler, checkbox.checked)"
                >
                <td class="list-checkbox">
                    <input type="checkbox" (click)="$event.stopPropagation();" (change)="onChangeLeftTransitList(traveler, $event.target.checked)" #checkbox/>
                </td>
                <td class="passenger-name">
                  <div *ngIf="!traveler.infantReference; else passengerNameWithInfantReference">
                    {{traveler.name}}
                  </div>
                  <ng-template #passengerNameWithInfantReference>
                    <div>{{traveler.name}}</div>
                    <div class="passenger-infant">
                      <img src="assets/img/link.svg" alt="">
                      <div>{{ getInfantRefenencePassenger(traveler.infantReference)?.name }}</div>
                    </div>
                  </ng-template>
                </td>
                <td>
                  <div *ngIf="!traveler.infantReference; else passengerTypeWithInfantReference">
                    <div>
                      {{traveler.passengerType}}
                    </div>
                  </div>
                  <ng-template #passengerTypeWithInfantReference>
                    <div>
                      <div>
                        {{traveler.passengerType}}
                      </div>
                      <div class="passenger-infant">{{ getInfantRefenencePassenger(traveler.infantReference)?.passengerType}}</div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="transit-buttons-group">
        <button
          class="transit-button btn"
          (click) = "moveToRight()"
          [ngClass] = "{
            'enabled-button': leftSideTransitPassengerList.length > 0,
            'disabled-button': leftSideTransitPassengerList.length === 0
          }">
          <span class="material-icons">
            arrow_forward
          </span>
        </button>
        <button
          class="transit-button btn"
          (click) = "moveToLeft()"
          [ngClass] = "{
            'enabled-button': rightSideTransitPassengerList.length > 0,
            'disabled-button': rightSideTransitPassengerList.length === 0
          }">
          <span class="material-icons">
            arrow_back
          </span>
        </button>
      </div>
      <div class="right-passenger-list">
        <div class="list-title">
          <h5 class="enabled-passenger-list">New Passenger List</h5>
        </div>
        <div class="table-container">
          <table class="passenger-table">
            <thead>
              <tr class="table-head">
                <th class="header-passenger-checkbox"></th>
                <th class="header-passenger-name">Name</th>
                <th>Passenger Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                [ngClass] = "{'row-checked': checkbox.checked}"
                class="table-body-row"
                *ngFor="let traveler of rightSideTravelers | removeInfantTravelersPipe"
                (click) = "checkbox.checked = !checkbox.checked; onChangeRightTransitList(traveler, checkbox.checked)"
                >
                  <td class="list-checkbox">
                      <input type="checkbox" (click)="$event.stopPropagation();" (change)="onChangeRightTransitList(traveler, $event.target.checked)" #checkbox/>
                  </td>
                  <td class="passenger-name">
                    <div *ngIf="!traveler.infantReference; else passengerNameWithInfantReference">
                      {{traveler.name}}
                    </div>
                    <ng-template #passengerNameWithInfantReference>
                      <div>{{traveler.name}}</div>
                      <div class="passenger-infant">
                        <img src="assets/img/link.svg" alt="">
                        <div>{{ getInfantRefenencePassenger(traveler.infantReference)?.name }}</div>
                      </div>
                    </ng-template>
                  </td>
                  <td>
                    <div *ngIf="!traveler.infantReference; else passengerTypeWithInfantReference">
                      <div>
                        {{traveler.passengerType}}
                      </div>
                    </div>
                    <ng-template #passengerTypeWithInfantReference>
                      <div>
                        <div>
                          {{traveler.passengerType}}
                        </div>
                        <div class="passenger-infant">{{ getInfantRefenencePassenger(traveler.infantReference)?.passengerType}}</div>
                      </div>
                    </ng-template>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ng-container *ngIf="!orderSplitingProcess.isProcess; else orderSplitProcessTemplate">
      <div class="footer-container">
        <div *ngIf="rightSideTravelers.length === 0" class="footer-notification">Please, select passenger for order spliting</div>
        <div class="footer-button-group">
          <button
            class="btn btn-secondary ml-3"
            (click)="emitDismiss.emit(true)">
            Cancel
          </button>
          <button
            [disabled] = "rightSideTravelers.length === 0"
            class="btn btn-primary ml-3"
            (click) = "sendOrderSplit()">
            Split Order
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #orderSplitProcessTemplate>
      <app-show-processing [processInfo]="orderSplitingProcess.processTitle"></app-show-processing>
  </ng-template>
</div>
