<button *ngIf="isBtn" class="btn btn-sm btn-outline-primary" (click)="openModalPNR(PNRmodal); popover.close()">PNR</button>
<ul class="mb-0" (click)="openModalPNR(PNRmodal); popover.close()"><li *ngIf="!isBtn">PNR</li></ul>

<ng-template #PNRmodal let-c="close" let-d="dismiss">
  <div class='pnr-modal-body'>
    <textarea class="text-area-mask" [(ngModel)]="PNR"></textarea>
    <div class="modal-bottom">
      <button class="btn btn-secondary" (click)="clearPNR(); d('closed')">Close</button>
      <button class="btn btn-primary" [cdkCopyToClipboard]="PNR" (click)="clearPNR(); c('ok')">Copy PNR</button>
    </div>
  </div>
</ng-template>
