<form [formGroup]="form" *ngIf="isFirstSearch" [class.flight-disabled]="isDisabled">
  <div class="form-row">
    <div class="col-3 mb-3">
      <select class="form-control" formControlName="dropdownOption" (change)="onSelect($event)">
        <option *ngIf="originDestinations.length > 1" [value]="'keep'">Keep segment</option>
        <option [value]="'change'">Change segment</option>
      </select>
    </div>
  </div>
  <div class="form-row" *ngIf="form.get('dropdownOption').value === 'change'">
    <div class="d-flex align-items-center w-100">
      <div class="airport-select">
        <app-virtual-scroll-select
          [isAirportsDisabled]="isAirportsDisabled"
          [data]="airports"
          [uniqueSelectId]="1"
          [value]="destinationData.departure"
          [isSmall]="true"
          (emitValueChanged)="setDeparture($event)">
        </app-virtual-scroll-select>
      </div>
      <i class="material-icons arrow-right-icon">
        arrow_right_alt
      </i>
      <div class="airport-select">
        <app-virtual-scroll-select
          [isAirportsDisabled]="isAirportsDisabled"
          [data]="airports"
          [uniqueSelectId]="2"
          [value]="destinationData.arrival"
          [isSmall]="true"
          (emitValueChanged)="setArrival($event)">
        </app-virtual-scroll-select>
      </div>
      <div class="input-group picker-wp ml-4 mr-2">
        <input
          formControlName="date"
          ngbDatepicker
          #d="ngbDatepicker"
          (click)="d.toggle()"
          (dateSelect)="setDate($event)"
          (keydown.enter)="d.toggle()"
          [minDate]="minDates['departure_date[' + id + ']'] || currentDate"
          [maxDate]="maxDates ? maxDates['departure_date[' + id + ']'] : null"
          [ngClass]="{'invalidDate': invalidDate}"
          autocomplete="off"
          container="body"
          class="form-control form-control-appended"
          placeholder="Dep. Date"
          name="dp"
        >
        <div class="input-group-append">
          <button
            class="btn btn-primary btn-icon" (click)="d.toggle()">
            <i class="material-icons">event</i>
          </button>
        </div>
      </div>
      <div class="ml-auto">
        <app-timepicker
          [timeValue]="form.get('time').value"
          (emitTime)="setSelectedTime($event)">
        </app-timepicker>
      </div>
    </div>
  </div>
</form>

<div class="destination-item-wp" [formGroup]="form" *ngIf="isNextSearch">
  <ng-container *ngIf="bookingType === 'RT' && id === 0">
    <ng-container [ngTemplateOutlet]="originDestinationTemplate"></ng-container>
    <div class="ml-4 mr-1">
      <app-datepicker-range [hasReset]="false" (emitRangeChange)="setDateRange($event)" formControlName='dateRange'></app-datepicker-range>
    </div>
  </ng-container>

  <ng-container *ngIf="bookingType !== 'RT'">
    <ng-container [ngTemplateOutlet]="originDestinationTemplate"></ng-container>
    <div class="input-group picker-wp ml-4 mr-1">
      <input
        formControlName="date"
        ngbDatepicker
        #d="ngbDatepicker"
        (click)="d.toggle()"
        (dateSelect)="setDate($event)"
        (keydown.enter)="d.toggle()"
        [minDate]="minDates['departure_date[' + id + ']'] || currentDate"
        [maxDate]="maxDates ? maxDates['departure_date[' + id + ']'] : null"
        [ngClass]="{'invalidDate': invalidDate}"
        autocomplete="off"
        container="body"
        class="form-control form-control-appended"
        placeholder="Dep. Date"
        name="dp"
      >
      <div class="input-group-append">
        <button
          class="btn btn-primary btn-icon" (click)="d.toggle()">
          <i class="material-icons">event</i>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #originDestinationTemplate>
  <div class="mr-3">
    <app-virtual-scroll-select
      [isAirportsDisabled]="isAirportsDisabled"
      [data]="airports"
      [uniqueSelectId]="1"
      [value]="destinationData.departure"
      [isSmall]="true"
      (emitValueChanged)="setDeparture($event)">
    </app-virtual-scroll-select>
  </div>
  <button
    class="btn btn-primary btn-icon mr-3"
    (click)="airportCastling()"
    [disabled]="isAirportsDisabled">
    <i class="material-icons">swap_horiz</i>
  </button>
  <div class="mr-1">
    <app-virtual-scroll-select
      [isAirportsDisabled]="isAirportsDisabled"
      [data]="airports"
      [uniqueSelectId]="2"
      [value]="destinationData.arrival"
      [isSmall]="true"
      (emitValueChanged)="setArrival($event)">
    </app-virtual-scroll-select>
  </div>
</ng-template>
