import { Pipe, PipeTransform } from '@angular/core';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {after, before} from "../constants";
import {Dictionary} from "../types/dictionary";

@Pipe({
  name: 'faded'
})
export class FadedPipe implements PipeTransform {

  transform(date: NgbDateStruct, controls: any, searchRow: any[], i: number, hoveredDate = null, flightType: string, returnDate = false): boolean {
    let faded = false;
    let depPrefix = 'departure_date[';
    let arrPrefix = 'arrival_date[';

    if (flightType === Dictionary.FLIGHT_TYPE_ROUND_TRIP) {
      if (returnDate && controls[`${depPrefix}${i}]`]?.value && Object.keys(controls[`${depPrefix}${i}]`]?.value).length && hoveredDate) {
        let one = controls[`${depPrefix}${i}]`].value;
        let two = hoveredDate;
        if (after(date, one) && before(date, two)) {
          faded = true;
        }
      }
      if (controls[`${depPrefix}${i}]`]?.value && Object.keys(controls[`${depPrefix}${i}]`]?.value).length && 
          controls[`${arrPrefix}${i}]`]?.value && Object.keys(controls[`${arrPrefix}${i}]`]?.value).length) {
        let one = controls[`${arrPrefix}${i}]`].value;
        let two = controls[`${depPrefix}${i}]`].value;
        if (after(date, two) && before(date, one)) {
          faded = true;
        }
      }
    } else {
      if (controls[`${depPrefix}${searchRow[i]?.controlIndex}]`]?.value && searchRow[i]?.controlIndex && !Object.keys(controls[`${depPrefix}${searchRow[i]?.controlIndex}]`].value).length &&
        Object.keys(controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`].value).length && hoveredDate) {
        let one = controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`].value;
        let two = hoveredDate;
        if (after(date, one) && before(date, two)) {
          faded = true;
        }
      }
      if (controls[`${depPrefix}${searchRow[i]?.controlIndex}]`]?.value && Object.keys(controls[`${depPrefix}${searchRow[i]?.controlIndex}]`].value).length &&
        controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`]?.value && Object.keys(controls[`${depPrefix}${searchRow[i - 1].controlIndex}]`]?.value).length) {
        let one = controls[`${depPrefix}${searchRow[i - 1].controlIndex}]`]?.value;
        let two = controls[`${depPrefix}${searchRow[i].controlIndex}]`].value;
        if (after(date, one) && before(date, two)) {
          faded = true;
        }
      }
      if (controls[`${depPrefix}${searchRow[i]?.controlIndex}]`]?.value && Object.keys(controls[`${depPrefix}${searchRow[i]?.controlIndex}]`].value).length &&
        controls[`${depPrefix}${searchRow[i + 1]?.controlIndex}]`]?.value && Object.keys(controls[`${depPrefix}${searchRow[i + 1].controlIndex}]`]?.value).length) {
        let one = controls[`${depPrefix}${searchRow[i + 1].controlIndex}]`]?.value;
        let two = controls[`${depPrefix}${searchRow[i].controlIndex}]`]?.value;
        if (after(date, two) && before(date, one)) {
          faded = true;
        }
      }
    }

    return faded;
  }

}
