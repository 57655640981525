<div class="main-select-wp" [ngClass]="{'active': isShowList, 'has-error': hasError}">
  <button
    class="value-container"
    [ngClass]="{'small': isSmall, 'default': !isSmall}"
    (click)="showList()"
    (focus)="showList()"
    [disabled]="isAirportsDisabled">
    <ng-container *ngIf="city && value; else valueTemplate">
      <span class="first-value">{{city}}</span> <span class="second-value">{{value}}</span>
    </ng-container>
  </button>

  <div class="list-container" *ngIf="isShowList">
    <input class="input-search"
           [ngClass]="{'small': isSmall, 'default': !isSmall}"
           placeholder="Type in airport code..."
           type="text"
           (keyup.enter)="search()"
           (input)="onSearchInput($event)" #searchInput (keydown)="keyDownHandler($event, virtualScroll)">
    <button class="list-item free-item focus-background" *ngIf="inputValue && !showData.length"
            (click)="selectedFreeItem()" [disabled]="isAirportsDisabled">
      {{inputValue}}
    </button>
    <cdk-virtual-scroll-viewport [class.d-none]="!showData.length"
                                 [ngClass]="{'small': isSmall, 'default': !isSmall}"
                                 [style.height.px]="listHeight"
                                 [itemSize]="itemListHeight"
                                 #virtualScroll>
      <ng-container *cdkVirtualFor="let option of showData; index as idx" (isSelected)="true">
        <button class="list-item" type="button" (click)="selectedListItem(option)" (isSelected)="true"
                [ngClass]="{'focus-background': selectedIndex == idx || idx == 0}">
          {{option?.text}}
        </button>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ng-template #valueTemplate>
  <ng-container *ngIf="value">
    {{value}}
  </ng-container>
  <ng-container *ngIf="!value">
    <span class="placeholder">{{placeholder}}</span>
  </ng-container>
</ng-template>
