<div class="modal-header">
  <h3 class="modal-title">Upgrade order {{order?.id}}</h3>
  <button type="button" class="close" aria-label="Close" (click)="emitClose.emit()">
      <span aria-hidden="true">
        <i class="material-icons close-icon float-right ml-3">close</i>
      </span>
  </button>
</div>
<div class="modal-body modal-card-body-height bg-white" [ngSwitch]="step" >
  <div class="loader-wrapper" *ngIf="showOrderReshopLoader">
    <app-loader></app-loader>
  </div>

  <error-alert-block
    [error]="orderReshopError"
  ></error-alert-block>

  <ng-container *ngSwitchCase="1">
    <ng-container *ngIf="offers?.length">
      <app-options
        [showOptions]="true"
        [showSelectedOffer]="true"
        [columnsToShow]="{action: false}"
        [simpleOffers]="offers | filterSimpleOffer"
        [desireOffer]="desireOffer"
        [isReshop]="true"
        (onSelectedOffer)="onSelectedOffer($event)"
      ></app-options>
    </ng-container>
    <ng-container *ngIf="offers && !offers.length">
      <div class="alert alert-warning rounded mb-0">
        There are no offers to upgrade.
      </div>
    </ng-container>
    <ng-container *ngIf="orderReshopRepriceProcess.isProcess">
      <div class="order-reshop-reprice-process">
        <app-show-processing
          [processInfo]= "orderReshopRepriceProcess.processTitle">
        </app-show-processing>
      </div>
    </ng-container>
    <ng-container *ngIf="orderReshopRepriceError.message">
      <div class="mt-4">
        <error-alert-block
          [error]="orderReshopRepriceError"
        ></error-alert-block>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <div class="offering card mt-4">
      <div class="card-header">
        <app-offer-price-header-view
          [oldOffer]="order"
          [offer]="desireOffer">
        </app-offer-price-header-view>
      </div>
      <div class="card-body bg-white max-h-fit">
        <app-offer-price-view
          [oldOffer]="order"
          [offer]="desireOffer">
        </app-offer-price-view>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="3">
    <app-show-processing *ngIf="changeOrderProcess.isProcess"
                         [processInfo]= "changeOrderProcess.processTitle">
    </app-show-processing>
    <app-payment-form
      [allowedPaymentMethods]="desireOffer.allowedPaymentMethods"
      [totalPrice]="desireOffer.price.consumer.total"
      [currency]="desireOffer.price.consumer.currency"
      [cardSurchargesMap]="desireOffer.cardSurchargesMap"
      [showTotalPrice]="true"
      [pingProceed]="pingProceed"
      [paymentError]="orderChangeError"
      (emitFormData)="onEmitFormData($event)"
    >
    </app-payment-form>
  </ng-container>

  <ng-container *ngSwitchCase="4">
    <div class="alert alert-success rounded mb-0">
      Upgrade completed successfully.
    </div>
  </ng-container>

</div>
<div class="modal-footer">
  <button
    *ngIf="step === 1"
    type="button"
    class="btn btn-secondary"
    (click)="emitClose.emit()">
    Cancel
  </button>
  <button
    *ngIf="step > 1 && step !== 4"
    type="button"
    class="btn btn-secondary"
    (click)="step = step - 1"
    [disabled]="orderReshopRepriceProcess.isProcess || changeOrderProcess.isProcess">
    Back
  </button>
  <button
    *ngIf="step === 1"
    type="button"
    class="btn btn-primary"
    (click)="proceedAction()"
    [disabled]="!proceedBtnIsAvailable || orderReshopRepriceProcess.isProcess">
    Proceed
  </button>
  <button
    *ngIf="step === 2"
    type="button"
    class="btn btn-primary"
    (click)="step = 3;"
    [disabled]="!proceedBtnIsAvailable">
    Payment
  </button>
  <button
    *ngIf="step === 3"
    type="button"
    class="btn btn-primary"
    [disabled]="changeOrderProcess.isProcess"
    (click)="pingProceed = pingProceed + 1">
    Pay
  </button>
  <button
    *ngIf="step === 4"
    type="button"
    class="btn btn-secondary"
    (click)="emitClose.emit()">
    Close
  </button>
</div>
