import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ProfilesService} from '../../services/profiles.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CorporateDiscountCodesService} from '../../services/corporate-discount-codes.service';
import {HelpersService} from "../../services/helpers.service";


@Component({
  selector: 'app-profiles-bar',
  templateUrl: './profiles-bar.component.html',
  styleUrls: ['./profiles-bar.component.scss']
})
export class ProfilesBarComponent implements OnInit, OnDestroy {

  @Input() leftLoader;
  @Input() noInputFocus;
  @Input() placeholder = 'Start typing your corporate ...';
  @Input() useNewDesign = false;
  @Output() emitIsSelectedCompany = new EventEmitter();
  @Output() emitIsSelectedCompanyInfo = new EventEmitter();

  validateTriggered = false;
  corporateSelectData: any[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();


  constructor(
    private profilesService: ProfilesService,
    private ls: LocalStorageService,
    public modalService: NgbModal,
    private corporateDiscountCodesService: CorporateDiscountCodesService,
    private helpersService: HelpersService
  ) { }

  ngOnInit() {
  }

  searchCorporates(event) {
    if (!event) {
      this.corporateSelectData = [];
      return;
    }

    this.profilesService.searchCorporates(event)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body && res.body.accounts) {
          const corporateCodes = {};
          res.body.accounts.forEach(corporate => {
            this.helpersService.removeFalsyKeys(corporate.discount_codes);
            this.helpersService.removeFalsyKeys(corporate.loyalty_program_discount_codes);

            if (corporate.discount_codes && Object.keys(corporate.discount_codes).length > 0) {
              corporateCodes[corporate.id] = {
                ...(corporateCodes[corporate.id] || {}),
                discountCodes: corporate.discount_codes,
              };
            }

            if (corporate.loyalty_program_discount_codes && Object.keys(corporate.loyalty_program_discount_codes).length > 0) {
              corporateCodes[corporate.id] = {
                ...(corporateCodes[corporate.id] || {}),
                loyaltyProgramCodes: corporate.loyalty_program_discount_codes,
              };
            }
          });

          this.corporateDiscountCodesService.setCorporateCodes = corporateCodes;

          this.corporateSelectData = res.body.accounts.map(val => {
            return {
              id: val.id,
              text: val.name,
              corporate_id: val.corporate_id,
              remarks: val.remarks,
              account_number: val.account_number,
              loyalty_program_discount_codes: val.loyalty_program_discount_codes
            };
          });
        } else {
          this.corporateSelectData = [];
        }
      }, err => console.log(err));
  }

  /**
   * emitSearchString
   * @param event
   */
  onEmitCorporateSearchString(event) {
    this.searchCorporates(event);
  }

  /**
   * emitSelectedItem
   * @param event
   */
  onSelectProfilesCompany(event) {
    if (event) {
      this.ls.setProfileCorporate(event);
      this.emitIsSelectedCompany.emit(true);
      this.emitIsSelectedCompanyInfo.emit({id: event.id, text: event.text});
    } else {
      this.ls.removeProfileCorporate();
      this.emitIsSelectedCompany.emit(false);
    }
  }

  open(content, size: 'lg' | 'sm' = 'lg', windowClass = '') {
    this.modalService.open(content, {
      size, windowClass
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
