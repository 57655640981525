<div class="container mt-3">
  <div class="row">
    <div class="col order-tabset">
      <ngb-tabset #orderListTabs (tabChange)="onTabSelected($event?.nextId)">
        <ngb-tab [id]="TabId.All" title="All">
        </ngb-tab>
        <ngb-tab [id]="TabId.Pending" [title]="'On Hold' | showNumberOfItem: counters.pending">
        </ngb-tab>
        <ngb-tab [id]="TabId.Ticketed" [title]="'Ticketed' | showNumberOfItem: counters.ticketed">
        </ngb-tab>
        <ngb-tab [id]="TabId.Cancelled" [title]="'Cancelled' | showNumberOfItem: counters.cancelled">
        </ngb-tab>
        <ngb-tab [id]="TabId.Voided" [title]="'Voided' | showNumberOfItem: counters.voided">
        </ngb-tab>
        <ngb-tab [id]="TabId.Issuing" [title]="'Issuing' | showNumberOfItem: counters.issuing">
        </ngb-tab>
        <ngb-tab [id]="TabId.Expired" [title]="'Expired' | showNumberOfItem: counters.expired">
        </ngb-tab>
        <ngb-tab [id]="TabId.Unknown" [title]="'Unknown' | showNumberOfItem: counters.unknown">
        </ngb-tab>
        <ngb-tab [id]="TabId.Notified">
          <ng-template ngbTabTitle>
            <div class="d-flex gap-4">
              <i class="material-symbols-outlined exclamation-icon problem hovered align-self-center">
                exclamation
              </i>
              <span>
                {{"Notifications" | showNumberOfItem: counters.notified}}
              </span>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="Splitter-1" disabled="true" [title]="">
        </ngb-tab>
        <ngb-tab id="search">
          <ng-template ngbTabTitle>
            <button class="btn btn-search" (click)="toggleSearch()">
              <span>Search</span>
              <span class="material-symbols-outlined fs-20" [@rotate]="showSearch">
                expand_more
              </span>
            </button>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="order-search-card-wp" [@collapse]="!showSearch">
              <div class="card">
                <div class="card-body order-list-filter">
                  <form [formGroup]="form">
                    <div class="form-row">
                      <div class="col-md-10">
                        <div class="form-row">
                          <div class="col-md-11">
                            <div class="form-row">
                              <div class="form-group-xs col-md-4 col-lg-4 col-xl-3">
                                <label>Provider</label>
                                <select formControlName="provider" class="form-control">
                                  <option value="">All</option>
                                  <option *ngFor="let provider of (helpers.allProvidersObj | pairs)" [value]="provider[0]">({{provider[0] | getProviderName}}) {{ provider[1] }}</option>
                                </select>
                              </div>
                              <div class="form-group-xs col-md-3 col-lg-3 col-xl-2">
                                <label for="form-operatingCarrier" class="text-nowrap">Operating Carrier</label>
                                <input formControlName="operatingCarrier"
                                       class="form-control"
                                       placeholder="BA"
                                       name="form-operatingCarrier"
                                       id="form-operatingCarrier"
                                       container="body"
                                       maxLength="2"
                                       appLettersNumbersOnly
                                       appUppercaseOnly
                                >
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-4 col-xl-3">
                                <label for="form-id">Order ID</label>
                                <input appTrim [trimSpaces]="true"
                                       formControlName="id" id="form-id" class="form-control" type="text"
                                       placeholder="AGW-XXXXXXXXX"/>
                              </div>
                              <div class="form-group-xs col-md-3 col-lg-3 col-xl-2">
                                <label for="form-pnr">PNR</label>
                                <input appTrim [trimSpaces]="true"
                                       formControlName="pnr" class="form-control" id="form-pnr"
                                       placeholder="MALK8E">
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-3 col-xl-2">
                                <label for="form-status">Status:</label>
                                <select formControlName="status" id="form-status" class="form-control">
                                  <option value="">All</option>
                                  <option [ngValue]="'Pending'">On Hold</option>
                                  <option [ngValue]="'Ticketed'">Ticketed</option>
                                  <option [ngValue]="'Started'">Started</option>
                                  <option [ngValue]="'Cancelled'">Cancelled</option>
                                  <option [ngValue]="'Blocked'">Blocked</option>
                                  <option [ngValue]="'Completed'">Completed</option>
                                  <option [ngValue]="'Voided'">Voided</option>
                                  <option [ngValue]="'Unused'">Unused</option>
                                  <option [ngValue]="'wasTicketed'">Was Ticketed</option>
                                  <option [ngValue]="'Issuing'">Issuing</option>
                                  <option [ngValue]="'Expired'">Expired</option>
                                  <option [ngValue]="'Unknown'">Unknown</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-10">
                        <div class="form-row">
                          <div class="col-md-11">
                            <div class="form-row">
                              <div class="form-group-xs col-md-4 col-lg-3">
                                <label>Origin</label>
                                <app-input-dropdown
                                  [placeholder]="'FRA'"
                                  [listData]="showOriginData"
                                  (emitSearchString)="onSearchInput('origin', $event)"
                                  (emitSelectedItem)="onSelectValueChanged('origin', $event?.id)"
                                >
                                </app-input-dropdown>
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-3">
                                <label>Destination</label>
                                <app-input-dropdown
                                  [placeholder]="'LHR'"
                                  [listData]="showDestinationData"
                                  (emitSearchString)="onSearchInput('destination', $event)"
                                  (emitSelectedItem)="onSelectValueChanged('destination', $event?.id)"
                                >
                                </app-input-dropdown>
                              </div>
                              <div class="form-group-xs col-md-3 col-lg-2">
                                <label for="form-departureDate">Departure Date</label>
                                <input formControlName="departureDate"
                                       ngbDatepicker
                                       #d="ngbDatepicker"
                                       (click)="d.toggle()"
                                       class="form-control"
                                       placeholder="Dep. Date"
                                       name="form-departureDate"
                                       id="form-departureDate"
                                       container="body"
                                >
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-3 col-xl-2">
                                <label>Booking Type:</label>
                                <select formControlName="bookingType" class="form-control">
                                  <option value="">All</option>
                                  <option *ngFor="let type of (searchTypeOptions | pairs)" [value]="type[0]">{{ type[1] }}
                                </select>
                              </div>
                              <div class="form-group-xs col-md-8 col-lg-5">
                                <label>Booking period</label>
                                <app-datepicker-range (emitValueChange)="resetBookingDateRange($event)" formControlName='bookingDateRange'></app-datepicker-range>
                              </div>
                              <div class="form-group-xs col-md-8 col-lg-5">
                                <label>Issuing period</label>
                                <app-datepicker-range (emitValueChange)="resetIssuingDateRange($event)" formControlName='issuingDateRange'></app-datepicker-range>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-10">
                        <div class="form-row">
                          <div class="col-md-11">
                            <div class="form-row">
                              <div class="form-group-xs col-md-4 col-lg-3">
                                <label for="form-psg-name">Passenger's Name</label>
                                <input formControlName="psgName" class="form-control" id="form-psg-name"
                                       placeholder="Name">
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-3">
                                <label for="form-psg-surname">Passenger's Surname</label>
                                <input formControlName="psgSurname" class="form-control" id="form-psg-surname"
                                       placeholder="Surname">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-md-10">
                        <div class="form-row">
                          <div class="col-md-11">
                            <div class="form-row">
                              <div class="form-group-xs col-md-4 col-lg-3">
                                <label for="form-ticketNumber">Ticket #/ EMD</label>
                                <input appTrim [trimSpaces]="true"
                                       formControlName="ticketNumber" class="form-control" id="form-ticketNumber"
                                       placeholder="176 2102704343">
                              </div>
                              <div class="form-group-xs col-md-4 col-lg-3" *ngIf="showSubagencies && agencies && agencies.length">
                                <label>Agency: </label>
                                <select class="form-control" formControlName="agency" (change)="onChangeAgency($event.target.value)">
                                  <option value="">All</option>
                                  <option *ngFor="let agency of agencies" [value]="agency">{{ agency }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col text-right text-nowrap">
                        <div *ngIf="(helpers.godMode$ | async).isEnabled"
                             class="export-dropdown d-inline-flex mr-3" container="body" ngbDropdown placement="bottom-right" autoClose="outside" #exportDropdown="ngbDropdown">
                          <button ngbDropdownToggle
                                  class="btn btn-outline-primary">
                            <i class="fas fa-file-export"></i>
                            <span class="ml-3">Export</span>
                          </button>
                          <div class="export-dropdown-menu" ngbDropdownMenu [formGroup]="emailForm">
                            <div class="form-row mb-3">
                              <div class="col-lg-12">
                                <div *ngFor="let email of recipientEmails; last as last">
                                  <label class="checkbox custom-checkbox-1" [class.mb-0]="last" [for]="email">
                                    <input type="checkbox"
                                           [id]="email"
                                           (change)="onPassengerEmailsChange(email)"
                                           [checked]="selectedRecipientEmails.includes(email)"/>
                                    <div class="checkbox__checkmark"></div>
                                    <div class="checkbox__body">{{email | lowercase}}</div>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="form-row mb-3">
                              <div class="col">
                                <input
                                  [validate]="emailForm.get('email').dirty"
                                  class="form-control"
                                  type="text"
                                  placeholder="web@example.com"
                                  formControlName="email"
                                >
                              </div>
                              <div class="col-auto">
                                <button class="btn btn-tonal-primary rounded-sm d-block"
                                        (click)="addEmail()"
                                        [disabled]="emailForm.get('email').invalid">
                                  <i class="fa fa-plus fs-12"></i>
                                </button>
                              </div>
                            </div>

                            <div class="form-row">
                              <div class="col">
                                <button class="btn btn-tonal-primary w-100 h-38"
                                        (click)="export(exportDropdown)"
                                        [disabled]="!selectedRecipientEmails.length">
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-primary btn-search" (click)="search()">
                          <i class="fas fa-search"></i>
                          <span class="ml-3">Search</span>
                        </button>
                      </div>
                      <div class="col">
                        <button class="btn btn-secondary" (click)="clearSearch()" *ngIf="hasChanges">Clear</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>


  <div class="row" *ngIf="showLoader">
    <div class="col">
      <div class="loader-wrapper">
        <app-loader></app-loader>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="warningMessage.length">
    <div class="col">
      <div class="alert alert-warning mb-2 mt-3">
        <ul>
          <li *ngFor="let item of warningMessage">{{item}}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row" [class.mt-3]="selectedTab !== TabId.Search" *ngIf="!showLoader">
    <div class="col">
      <div class="card orders-list">
        <div class="card-body">
          <div class="row">
            <div class="col d-flex">
              <div class="form-group mr-4" *ngIf="!isConsultant()">
                <label>Scope: </label>
                <select class="form-control" [(ngModel)]="selectedAgent" (change)="search()">
                  <option value="all">All</option>
                  <option value="agent_only">My orders</option>
                </select>
              </div>

              <div class="form-group mr-4">
                <label for="sort">Sort By:</label>
                <select id="sort" class="form-control" (change)="changeSort($event)">
                  <ng-container *ngFor="let opt of sortOptions">
                    <option
                      *ngIf="!opt.statuses || opt.statuses.indexOf(form.controls['status'].value) > -1 || selectedTab === 'Active'"
                      [value]="opt.sort"
                      [selected]="sortSelected === opt.sort"
                    >{{opt.label}}{{ (activeStatuses.indexOf(form.controls['status'].value) > -1 && opt.sort === 'departure_date_desc') ? ' DESC' : '' }}</option>
                  </ng-container>
                </select>
              </div>

              <div class="filter-by-corporate form-group mr-4">
                <label for="sort">Filter By:</label>
                <div class="is-filtered-corporate" *ngIf="!isFilteredCorporate">
                  <span class="filtered-corporate-span">
                        <span class="click-select">{{selectedCorporate}}</span>
                        <span class="click-close" (click)="onFilterCorporate(null);">
                          <i class="material-icons ml-1" (click)="onFilterCorporate(null)">close</i>
                        </span>
                  </span>
                </div>
                <div *ngIf="isFilteredCorporate">
                  <app-profiles-bar (emitIsSelectedCompanyInfo)="onFilterCorporate($event)" [placeholder]="'Corporate'"></app-profiles-bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-4">
        <table class="table table-default table-hover mb-0" *ngIf="orders.length">
          <thead>
          <tr>
            <th *ngIf="showSubagencies && agencies && agencies.length">Agency</th>
            <th style="width: 100px;">Provider</th>
            <th>PNR</th>
            <th>PAX</th>
            <th class="text-nowrap" style='width: 140px'>Booking Date</th>
            <th *ngIf="selectedTab === TabId.Pending">Expiration</th>
            <th>Type</th>
            <th style='width: 100px'>O&D</th>
            <th class="text-nowrap" style='width: 155px'>Departure Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr class="cursor-pointer" (click)="showOrder(order, $event)" *ngFor="let order of orders">
            <td *ngIf="showSubagencies && agencies && agencies.length">
              <div class='agency'
                  [ngbPopover]="order.agent"
                  [triggers]="'hover'"
                  placement="top"
                  popoverClass="popover-wp">
                {{ order.agency }}</div>
            </td>
            <td>
              <div class="m-auto" style="max-height: 50px;max-width:50px">
                <app-airline-img
                  [airlineID]="order.owner"
                  [title]="order.owner | getImageDescription: order.flights"
                ></app-airline-img>
              </div>
            </td>
            <td>{{ order.pnr }}</td>
            <td>
              <ng-container *ngIf="order.passengers">
                <ng-container *ngFor="let passenger of order.passengers | sortPassengers; first as first">
                  <div class="d-flex align-items-center justify-content-center gap-5 text-nowrap passenger-wp"
                       [ngbPopover]="passengersInfo"
                       popoverClass="passenger-popover-wp popover-wp"
                       [triggers]="'hover'"
                       placement="top">
                    <ng-container *ngIf="first">
                      <span [class.short-passenger-name]="showSubagencies">{{passenger.data.name | titlecase}} {{passenger.data.surname | titlecase}}</span>
                      <span class="travelers-count" *ngIf="order.passengers?.length > 1">+ {{order.passengers?.length - 1}}</span>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #passengersInfo>
                  <div class="taxes-info-tooltip-wp" >
                    <ul>
                      <li *ngFor="let p of order.passengers | sortPassengers">
                        <span class="pass-type"> ({{p?.passengerType}}) </span>
                        <span class="pass-name" >{{p?.data?.name | titlecase}} {{p?.data?.surname | titlecase}}</span>
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </ng-container>
            </td>
            <td class="text-nowrap">
              <ng-container *ngIf="order.bookingDate">
                <span class="booking-date">
                  {{(order.bookingDate | dateFormat) | async}}
                </span>
              </ng-container>
            </td>
            <td class="text-nowrap" *ngIf="selectedTab === TabId.Pending">
              <ng-container *ngIf="order.status === ORDER_STATUS.PENDING && order.paymentTimeLimit">
                <app-date-with-popover [date]="order.paymentTimeLimit">
                </app-date-with-popover>
              </ng-container>
            </td>
            <td class="booking-type-icon">
              <ng-container
                [ngSwitch]="order.bookingType"
                class="material-icons"
              >
                <div [ngbPopover]="bookingTypeTitle"
                    [triggers]="'hover'"
                    popoverClass="popover-wp"
                    placement="top"
                    class='flight-type-wp lh-1'
                >
                  <i *ngSwitchCase="'OW'" class="material-icons">arrow_right_alt</i>
                  <i *ngSwitchCase="'RT'" class="material-icons">swap_horiz</i>
                  <i *ngSwitchCase="'MC'" class="material-icons">linear_scale</i>
                </div>
              </ng-container>
              <ng-template #bookingTypeTitle>
                <ng-container [ngSwitch]="order.bookingType">
                  <div *ngSwitchCase="'OW'">One Way</div>
                  <div *ngSwitchCase="'RT'">Round Trip</div>
                  <div *ngSwitchCase="'MC'">Multi City</div>
                </ng-container>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="order.flights">
                <div *ngFor="let route of order.flights" style="white-space: nowrap;">
                  {{ route.departure.airportCode }} - {{ route.arrival.airportCode }}
                </div>
              </ng-container>
            </td>
            <td>
              <div
                  *ngIf="order.flights"
                  [ngbPopover]="popover"
                  popoverClass="popover-wp"
                  [triggers]="'hover'"
                  class="text-nowrap">
                {{ (order.flights[0].departure.date | dateFormat) | async }} {{ order.flights[0].departure.time }}
              </div>
              <ng-template #popover>
                <span class="font-weight-bold">Days left: </span>
                <span>{{ getDepartureRemaining(order) === 0 ? 'Today'
                  : getDepartureRemaining(order) > 0 ? getDepartureRemaining(order)
                    : '-' }}
                </span>
              </ng-template>

            </td>
            <td width="100">
              <app-order-status
                [status]="order.status"
                [statusText]="service.getOrderStatus(order.status)"
                [disruptionStatus]="order.disruptionStatus"
              ></app-order-status>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <ngb-pagination *ngIf="totalCount > pageSize" [collectionSize]="totalCount"
                      [(page)]="page" [pageSize]="pageSize" [maxSize]="7" [boundaryLinks]="true"
                      (pageChange)="search()"
                      class="d-flex justify-content-end"
      ></ngb-pagination>
    </div>
  </div>

  <div class="row" *ngIf="!showLoader && !orders.length">
    <div class="col">
      No orders found
    </div>
  </div>
</div>
