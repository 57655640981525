import { Pipe, PipeTransform } from '@angular/core';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {equals} from "../constants";
import {Dictionary} from "../types/dictionary";

@Pipe({
  name: 'daysRange'
})
export class DaysRangePipe implements PipeTransform {

  transform(date: NgbDateStruct, controls: any, searchRow: any[], i: number, flightType: string): boolean {
    let range = false;
    let depPrefix = 'departure_date[';
    let arrPrefix = 'arrival_date[';

    if (flightType === Dictionary.FLIGHT_TYPE_ROUND_TRIP) {
      if (controls[`${depPrefix}${i}]`].value &&
        equals(date, controls[`${depPrefix}${i}]`].value)) {
        range = true;
      }
      if (controls[`${arrPrefix}${i}]`].value &&
        equals(date, controls[`${arrPrefix}${i}]`].value)) {
        range = true;
      }
    } else {
      if (controls[`${depPrefix}${searchRow[i].controlIndex}]`].value &&
        equals(date, controls[`${depPrefix}${searchRow[i].controlIndex}]`].value)) {
        range = true;
      }
      if (controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`] &&
        controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`].value &&
        equals(date, controls[`${depPrefix}${searchRow[i - 1]?.controlIndex}]`].value)) {
        range = true;
      }

      if (controls[`${depPrefix}${searchRow[i + 1]?.controlIndex}]`] &&
        controls[`${depPrefix}${searchRow[i + 1]?.controlIndex}]`].value &&
        equals(date, controls[`${depPrefix}${searchRow[i + 1]?.controlIndex}]`].value)) {
        range = true;
      }
    }

    return range;
  }

}
