import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Provider,
} from '@angular/core';
import {Dictionary} from "../../types/dictionary";
import {HelpersService} from "../../services/helpers.service";
import {LocalStorageService} from "../../services/local-storage.service";
import {FormControl, FormGroup} from "@angular/forms";
import {NgbDateParserFormatter, NgbDateStruct, NgbDropdownConfig} from "@ng-bootstrap/ng-bootstrap";
import {AirGatewayDateFormatter} from "../../services/air-gateway-date-formatter.service";

@Component({
  selector: 'app-destination-choose-item',
  templateUrl: './destination-choose-item.component.html',
  styleUrls: ['./destination-choose-item.component.scss'],
  providers: [
    NgbDropdownConfig,
    <Provider>{
      provide: NgbDateParserFormatter,
      useClass: AirGatewayDateFormatter,
    },
  ],
})
export class DestinationChooseItemComponent implements OnInit, OnDestroy {

  @Input() destinationData;
  @Input() originDestinations;
  @Input() id;
  @Input() isAirportsDisabled;
  @Input() isDisabled = false;
  @Input() invalidDate;
  @Output() emitChangedModel = new EventEmitter();
  @Output() emitChangedValue = new EventEmitter();
  @Output() emitChangedOriginDestinations = new EventEmitter();
  @Output() emitSelectedDateRange = new EventEmitter();
  @Input() minDates;
  @Input() maxDates;
  @Input() currentDate: NgbDateStruct;
  @Input() isFirstSearch = true;
  @Input() isNextSearch = false;
  @Input() bookingType: 'OW' | 'RT' | 'MC';

  airports = Dictionary.getAirports();
  departureDate;
  form: FormGroup;
  dropdownValue;

  constructor(
    private helpersService: HelpersService,
    public ls: LocalStorageService,
  ) {}

  ngOnInit() {
    const dateRange = this.bookingType === 'RT' ?
                        this.originDestinations[0].departureDate + '/' + this.originDestinations[1].departureDate : '';
    this.departureDate = this.helpersService.formatDateToNgbDateStruct(this.destinationData.departureDate);
    this.form = new FormGroup({
      date: new FormControl(this.departureDate),
      dropdownOption: new FormControl(this.originDestinations.length > 1 ? 'keep' : 'change'),
      dateRange: new FormControl(dateRange),
      time: new FormControl('')
    });
  }
  ngOnDestroy(): void {
  }

  airportCastling() {
    if (!this.isAirportsDisabled) {
      if (this.bookingType === 'RT') {
        this.originDestinations.map(od => [od.departure, od.arrival] = [od.arrival, od.departure]);
        this.emitChangedOriginDestinations.emit(this.originDestinations);
      } else {
        [this.destinationData.departure, this.destinationData.arrival] = [this.destinationData.arrival, this.destinationData.departure];
        this.emitChangedModel.emit(this.destinationData);
      }
    }
  }

  setDate(event) {
    this.destinationData.departureDate = HelpersService.getFormattedDate(event);
    this.emitChangedModel.emit(this.destinationData);
  }

  setArrival(event) {
    if (this.bookingType === 'RT') {
      this.originDestinations[0].arrival = event.id;
      this.originDestinations[1].departure = event.id;
      this.emitChangedOriginDestinations.emit(this.originDestinations);
    } else {
      this.destinationData.arrival = event.id;
      this.updateRoute();
      this.emitChangedModel.emit(this.destinationData);
    }
  }

  setDeparture(event) {
    if (this.bookingType === 'RT') {
      this.originDestinations[0].departure = event.id;
      this.originDestinations[1].arrival = event.id;
      this.emitChangedOriginDestinations.emit(this.originDestinations);
    } else {
      this.destinationData.departure = event.id;
      this.updateRoute();
      this.emitChangedModel.emit(this.destinationData);
    }
  }

  updateRoute() {
    this.destinationData.stringData = `${this.destinationData.departure} → ${this.destinationData.arrival}`;
  }

  onSelect(event) {
    this.dropdownValue = this.form.controls['dropdownOption'].value;
    let destinationData = {
      option: this.dropdownValue,
      destinationData: this.destinationData
    };
    this.emitChangedValue.emit(destinationData);
    if (this.dropdownValue !== 'newDate') {
      this.form.controls['date'].setValue(this.departureDate);
      this.destinationData.departureDate = HelpersService.getFormattedDate(this.departureDate);
      this.emitChangedModel.emit(this.destinationData);
    }
  }

  setDateRange(event) {
    let isRangeSelected = true;
    if (event.fromDate && event.toDate) {
      isRangeSelected = false;
      this.originDestinations[0].departureDate = HelpersService.getFormattedDate(event.fromDate);
      this.originDestinations[1].departureDate = HelpersService.getFormattedDate(event.toDate);
      this.emitChangedOriginDestinations.emit(this.originDestinations);
    }
    this.emitSelectedDateRange.emit(isRangeSelected);
  }

  setSelectedTime($event: any) {
    this.destinationData.time = $event;
    this.emitChangedModel.emit(this.destinationData);
  }
}
