import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener, Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerRangeComponent),
      multi: true
    }
  ]
})
export class DatepickerRangeComponent implements ControlValueAccessor {

  @Input() hasReset = true;
  @ViewChild('datepicker') datePicker: any;
  @Output() emitValueChange = new EventEmitter();
  @Output() emitRangeChange = new EventEmitter();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  maxDate: NgbDate | null;

  selectedDateRange;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private eRef: ElementRef) {
    // this.fromDate = calendar.getNext(calendar.getToday(), 'd', -10);
    // this.toDate = calendar.getToday();
    // this.maxDate = calendar.getToday();
  }

  @HostListener('document:click', ['$event']) clickOut(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.datePicker.open();
    } else {
      this.datePicker.close();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  writeValue(dataRange: any): void {
    if (dataRange) {
      this.onChange(dataRange);
      this.selectedDateRange = dataRange;

      this.fromDate = new NgbDate(+dataRange.split('/')[0].split('-')[0], +dataRange.split('/')[0].split('-')[1], +dataRange.split('/')[0].split('-')[2]);
      this.toDate = new NgbDate(+dataRange.split('/')[1].split('-')[0], +dataRange.split('/')[1].split('-')[1], +dataRange.split('/')[1].split('-')[2]);
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.writeValue(
        this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + '/' + this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day
      );
      this.datePicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.emitRangeChange.emit({fromDate: this.fromDate, toDate: this.toDate});
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  resetPeriod(event) {
    this.emitValueChange.emit('');
    event.stopPropagation();
  }
}
