import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../services/local-storage.service';
import * as Sentry from "@sentry/browser";
import {SentryService} from '../services/sentry.service';
import {ConfigService} from '../services/config.service';


class CustomHttpError extends Error {
  readonly operationsWithoutOwner = ['OrderList', 'ProviderList'];

  constructor(msg: string, status: string, response: string, operation: string, owner: string) {
    super(msg);

    const ownerPart = owner && !this.operationsWithoutOwner.includes(operation) && !operation.includes('/')
      ? ` [${owner}]`
      : '';
    this.name = `[BOOKINGPAD]${ownerPart} [${operation}] HttpError ${status}: ${response || this.message}`;

    Object.setPrototypeOf(this, CustomHttpError.prototype);
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  lastError = '';
  customHttpError = {};

  constructor(
    private configService: ConfigService,
    private ls: LocalStorageService,
    private sentryService: SentryService
  ) {}

  handleError(error) {
    const errorObj = Object.values(error)[0] && Object.values(error)[0]['name'] ? Object.values(error)[0] : error || {};
    let operation = errorObj.url?.split(`${environment.ndcApiEndpoint}`).pop().split('?').shift();
    operation = operation?.replace(/^v\d+\.\d+\//, '');

    if (errorObj.name === "HttpErrorResponse") {
      this.customHttpError = new CustomHttpError(JSON.stringify({
        httpCode: errorObj.status,
        headers: errorObj.headers,
        response: errorObj.error,
        httpError: errorObj.message
      }), errorObj.status, errorObj.error?.detail, operation, this.sentryService.owner);
    }

    if (this.lastError !== error.stack) {
      error.stack = (this.ls.sessionID ? '[SessionID: ' + this.ls.sessionID + '] ' + "\n" : '') + error.stack;
      error.name = '[BOOKINGPAD] ' + error.name;
      this.lastError = error.stack;
      if (environment.name !== 'dev') {
        const err = this.customHttpError instanceof CustomHttpError
                      ? this.customHttpError
                        : error.originalError || error.error || error;
        Sentry.captureException(err, scope => {
          scope.setUser({username: this.ls.email});
          scope.setExtra('CUSTOM DATA', JSON.stringify(this.sentryService.structuredContext));
          scope.setExtra('INDEXEDDB DATA', JSON.stringify(this.sentryService.lastIndexedDbDataToBeAdded));
          return scope;
        });
      }
    }

    setTimeout(() => {
      this.lastError = '';
    }, 1000);
    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    if (Object.keys(this.customHttpError).length === 0) {
      throw error;
    }
  }
}
