import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {DataTransferService} from '../../shared/services/data-transfer.service';
import {PostOrderDateChangeModel} from '../../shared/models/post-order-date-change.model';
import {takeUntil} from 'rxjs/operators';
import {NDCApiService} from '../../shared/services/ndc-api.service';
import {HelpersService} from '../../shared/services/helpers.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import moment from 'moment';
import {PostRepriceOrder} from '../../shared/models/post-date-change-with-id.model';
import {NotificationService} from '../../shared/services/notification.service';
import {MetaService} from '../../shared/services/meta.service';
import {Process} from "../../shared/interfaces/show-process";
import {GtmService} from "../../shared/services/gtm.service";
import { LocalStorageService } from '../../shared/services/local-storage.service';
import {convertPhoneIntoString} from '../../shared/adapters/v1_2/adapters';
import {OfferService} from "../../shared/services/offer.service";
import {fareRulesToNumberMap} from "../../shared/types/helper";
import {FlightInfo} from "../../shared/interfaces/offer";
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SeatMapService} from "../../shared/services/seat-map.service";
import { HttpErrorResponse } from '@angular/common/http';
import {PairsPipe} from 'ngx-pipes';
import {OWNERS} from '../../shared/constants';
import {SentryService} from "../../shared/services/sentry.service";
import {HttpCancelService} from "../../shared/services/http-cancel.service";


@Component({
  selector: 'app-order-change',
  templateUrl: './order-change.component.html',
  styleUrls: ['./order-change.component.scss']
})
export class OrderChangeComponent implements OnInit, OnDestroy {

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  private itinReshopSendData: PostOrderDateChangeModel;
  @Output() emitChangeSuccess = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  order;
  availableOffers;
  availableOffersForView = [];
  availableOffersForViewTable = [];
  filteredOffers = [];
  newChangeOrderDate;
  selectedTab = 'offer';
  showLoader = false;
  selectedOffer;
  offerFromItinReshopReprice: any;
  pingProceed = 0;
  getOffersByDateLoader = false;
  originDestinationsData = [];
  pageSize = 10;
  offerData;
  page = 1;
  id;
  owner;
  itinReshopModelValid = false;

  itinReshopError: any = {};
  itinReshopWarnings = [];
  itinReshopRepriceErrors = {};
  itinReshopRepriceWarnings = {};
  itinReshopRepriceCurrent = -1;
  changeOrderDateErrors: any;
  changeOrderDateWarnings = [];
  disabledData = [];


  sortOptions: any = {
    'price_asc': 'Lowest Price',
    'first_flight_dep': 'Earliest departure',
    'first_flight_arr': 'Earliest arrival',
    'last_flight_dep': 'Latest departure',
    'last_flight_arr': 'Latest arrival',
  };
  sortStrategy;
  checkedBaggage = '';
  refundableFares = 'light';
  changeOrderProcess: Process = {
    isProcess: false,
    processTitle: 'Order rebooking...'
  };
  changeReshopReprice: Process = {
    isProcess: false,
    processTitle: 'Order repricing...'
  };
  originDestinationForPerSegment = [];
  selectedOrderTypeTab: string;
  groupedOffersByFlight: any[][];
  fareRules: string;
  autoSelectFlightIndex: number[] = [];
  flightInfos: FlightInfo[];
  allAppropriateOffer: any[] = [];
  isShowShowAllButton = false;
  allOptions = [];
  disableSearchButton = false;
  invalidDate = [];

  seatAvailabilityResponseWarning = [];
  seatsSegments;
  seatAvailability;
  selectedServices = [];
  selectedSeats = [];
  OWNERS = OWNERS;
  getOfferDetailsLoader = false;
  totalOfferPrice: number;
  offerPriceCurrency: string;
  flightsForRebookingServices = [];
  flightsForRebookingSeats = [];
  minDates = {};
  maxDates = {};
  currentDate: NgbDateStruct;
  allowSearch = true;
  isFirstSearch = true;
  offerPriceError: any;


  constructor(
    public service: NDCApiService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public helpers: HelpersService,
    private meta: MetaService,
    public dataTransferService: DataTransferService,
    protected _notificationSvc: NotificationService,
    private gtmService: GtmService,
    private ls: LocalStorageService,
    public offerService: OfferService,
    public modalService: NgbModal,
    public seatMapService: SeatMapService,
    private sentryService: SentryService,
    private httpCancelService: HttpCancelService,
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.sortStrategy = 'price_asc';
    this.id = this.route.snapshot.paramMap.get('id');
    this.offerData = {...this.dataTransferService.changeDateOrderData};
    this.newChangeOrderDate = this.offerData.selectedDate;
    this.order = this.offerData.targetOrder;
    this.order.allowedPaymentMethods.none = !!this.order.allowedRequests.ticketed.RebookingOnHold;
    this.flightInfos = this.offerService.getFlightInfo(this.order);
    this.originDestinationsData = this.offerData.originDestinations;
    const currDate = new Date();
    this.currentDate = {
      year: currDate.getFullYear(),
      month: currDate.getMonth() + 1,
      day: currDate.getDate(),
    };
    this.createMinAndMaxDates();
    this.selectedOrderTypeTab = this.originDestinationsData?.length > 1 ? 'perSegment' : 'oneWay';
    this.originDestinationForPerSegment = this.originDestinationsData.map(originDestination => {
      return  {
        arrival: {
          airportCode: originDestination.arrival
        },
        departure: {
          airportCode: originDestination.departure
        }
      };
    });
    this.multiCityDateValidator();


    if (this.dataTransferService.changeDateOrderData.type === 'order') {
      this.sendRepriceOrder();
    }

    this.disabledData = this.originDestinationsData.map(originDestination => {
      const departureDateAndTime = originDestination.departureDate + ' ' + originDestination.departureTime;
        return this.order.status === 'Started' && moment().isAfter(departureDateAndTime)
      }
    );

    this.allOptions = this.originDestinationsData.map(od => this.originDestinationsData.length > 1 ? 'keep' : 'change');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private sendRepriceOrder() {
    const body: PostRepriceOrder = {
      id: this.order.id,
    };

    this.service.sendOrderReshopReprice(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);
        this.tabSelect('repriceSuccess');
        this.emitChangeSuccess.emit(true);
      }, res => {
        this.emitClose.emit(true);
        this.itinReshopRepriceErrors = this.helpers.getError(res);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
        if (this.helpers.isCriticalError(res)) {
          throw res;
        }
      });
  }

  actionAbort() {
    this.getOffersByDateLoader = false;
    this.allowSearch = true;
    this.httpCancelService.cancelPendingRequests();
  }

  sendNewDate() {
    this.getOffersByDateLoader = true;
    this.allowSearch = false;
    this.itinReshopError = {};
    this.itinReshopWarnings = [];
    this.itinReshopSendData = {
      id: this.order.id,
      originDestinations: []
    };

    this.originDestinationsData.map((item, index) => {
      let tempPayload = {
        departure: {
          airportCode: item.departure,
          terminalName: '',
          time: this.helpers.getColonTime(item.time),
          date: item.departureDate
        },
        arrival: {
          airportCode: item.arrival,
          terminalName: '',
          date: item.departureDate,
          time: '',
        },
      };

      if (this.allOptions[index] === 'keep') {
        tempPayload['type'] = 'keep';
      }
      this.itinReshopSendData.originDestinations.push(tempPayload);
    });

    this.availableOffersForViewTable = [];
    this.availableOffersForView = [];

    this.service.sendOrderReshop(this.itinReshopSendData)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, this.itinReshopSendData, 200, response);
        this.itinReshopRepriceErrors = {};
        this.itinReshopRepriceWarnings = {};
        this.page = 1;
        this.getOffersByDateLoader = this.isFirstSearch = false;
        this.allowSearch = true;
        this.itinReshopWarnings = this.helpers.getWarningsFromResponse(response);
        this.offerFromItinReshopReprice = null;

        const offers = response.offers?.some(offer => !!offer.flights) ? response.offers : [];
        this.allAppropriateOffer = offers;

        const offersAndFlightIndexes = this.offerService.getOffersAndFlightIndexes(offers, this.order, this.allOptions);
        this.autoSelectFlightIndex = offersAndFlightIndexes.indexes;
        this.availableOffersForViewTable = this.availableOffersForView = offersAndFlightIndexes.offers;

        this.isShowShowAllButton = this.availableOffersForViewTable.length === 0 && response.offers?.length > 0;

        this.applyFilter();
        this.scrollModalToSearch();
      }, error => {
        this.getOffersByDateLoader = false;
        this.allowSearch = true;
        this.itinReshopError = this.helpers.getError(error);
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, this.itinReshopSendData, error.status, error);
        if (this.helpers.isCriticalError(error)) {
          throw error;
        }
      });
  }

  disableSearch() {
    const keepOptions = ['keep', 'keep'];
    const indexArray = this.allOptions.map(el => {
      return keepOptions.indexOf(el);
    });
    return indexArray.indexOf(-1) === -1;
  }

  private addZero(num: number | string): number | string {
    return +num < 10 ? `0${num}` : num;
  }

  onSelectedOrder(event, index) {
    this.itinReshopRepriceCurrent = index;
    this.sendNewDateWithOfferId(event);
  }

  scrollModalToSearch() {
    setTimeout(() => {
      this.el.nativeElement.querySelector('.change-date-wp').scrollIntoView({behavior: "smooth"});
    }, 100);
  }

  onShowAllOffers() {
    this.autoSelectFlightIndex = [];
    this.availableOffersForViewTable = this.allAppropriateOffer;
    this.isShowShowAllButton = !this.allAppropriateOffer.length;
    this.applyFilter();
    this.scrollModalToSearch();
  }

  handleGridChange($event) {
    this.availableOffersForView = $event.offers;
    this.onSelectedSortStrategy();
    this.cdr.detectChanges();

    if (this.checkedBaggage !== $event.checkedBaggage || this.fareRules !== $event.fareRules) {
      this.checkedBaggage = $event.checkedBaggage;
      this.fareRules = $event.fareRules;
      this.applyFilter();
      return;
    }
  }

  onEmitFormData(event) {
    this.setChangeOrderProcess(true);
    this.changeOrderDateErrors = '';
    this.changeOrderDateWarnings = [];
    this.cdr.detectChanges();
    if (event.isValid) {
      const postData = {
        id: this.order.id,
        payment: event.formData,
        reshopOfferID: this.offerFromItinReshopReprice.offerID
      };

      switch (this.ls.appVersion) {
        case 'v1.2':
          if (postData.payment && postData.payment.phone) {
            postData.payment.phone = convertPhoneIntoString(postData.payment.phone);
          }
          break;
      }

      this.service.changeOrderDate(postData)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(response => {
          this.setChangeOrderProcess(false);
          this.gtmService.addEvent('OrderChange.ChangeDate');
          let body = response.body;

          this.changeOrderDateWarnings = this.helpers.getWarningsFromResponse(response);

          this.tabSelect('changeSuccess');
          this.emitChangeSuccess.emit(body);
        }, res => {
          this.setChangeOrderProcess(false);
          this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, postData, res.status, res);
          this.changeOrderDateErrors = this.helpers.getError(res);
          this.cdr.detectChanges();
          if (this.helpers.isCriticalError(res)) {
            throw res;
          }
        });
    }
  }

  sendNewDateWithOfferId(event) {
    this.selectedOffer = event;
    const body = {
      id: this.order.id,
      date: `${this.newChangeOrderDate.year}-${this.addZero(this.newChangeOrderDate.month)}-${this.addZero(this.newChangeOrderDate.day)}`,
      offerID: event.offerID
    };
    this.changeReshopReprice.isProcess = true;

    this.service.sendOrderReshopReprice(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, response);

        this.itinReshopRepriceWarnings[event.offerID] = this.helpers.getWarningsFromResponse(response);

        this.offerFromItinReshopReprice = response.body;

        this.offerFromItinReshopReprice.allowedRequests = {
          ServiceList: !!response.body?.servicesToRebook,
          SeatAvailability: !!response.body?.seatsToRebook,
        };
        this.flightsForRebookingServices = this.filterFlightsForRebook(response.body?.servicesToRebook, response.body?.flights);
        this.flightsForRebookingSeats = this.filterFlightsForRebook(response.body?.seatsToRebook, response.body?.flights);

        this.tabSelect('details');

        this.changeReshopReprice.isProcess = false;
        this.owner = this.sentryService.owner = this.offerFromItinReshopReprice.owner;
      }, err => {
        this.itinReshopRepriceErrors = this.helpers.getError(err);
        this.changeReshopReprice.isProcess = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, err.status, err);
        if (this.helpers.isCriticalError(err)) {
          throw err;
        }
      });
  }

  filterFlightsForRebook(servicesOrSeats, flights) {
    return flights.filter(({ key: key }) => servicesOrSeats?.some(({ flightKey: flightKey, rebook: rebook }) => (key === flightKey) && rebook));
  }

  tabSelect(tabName) {
    this.pingProceed = 0;
    switch (tabName) {
      case 'details' :
        if (this.offerFromItinReshopReprice) {
          this.selectedTab = 'details';
        }
        return;
      case 'offer' :
        this.selectedTab = tabName;
        return;
      case 'payment' :
          this.changeOrderDateErrors = '';
        if (this.offerFromItinReshopReprice) {
          if (this.selectedServices.length || this.selectedSeats.length) {
            this.sendOfferPrice();
          }
          this.selectedTab = 'payment';
        }
        return;
      case 'changeSuccess':
        this.selectedTab = 'changeSuccess';
        return;
      case 'repriceSuccess':
        this.selectedTab = 'repriceSuccess';
        return;
      case 'offerDetails':
        this.selectedTab = 'offerDetails';
        return;
    }
  }

  search() {
    this.page = this.page++;
  }

  getFormDataIfValid() {
    this.changeOrderDateErrors = '';
    this.pingProceed = ++this.pingProceed;
  }

  onChangeOriginDestinations(originDestinations) {
    this.originDestinationsData = originDestinations;
  }

  onChangeModel(destinationData, index) {
    this.originDestinationsData[index] = destinationData;
    this.updateMinAndMaxDates(destinationData, index);
    this.checkDateValidity(destinationData, index);
    this.multiCityDateValidator();
  }

  onChangeDropdownValue(destinationData, index) {
    this.allOptions[index] = destinationData.option;
    this.checkDateValidity(destinationData.destinationData, index);
  }

  checkDateValidity(destinationData: any, index: number) {
    if (this.allOptions[index] === 'newDate' && destinationData.departureDate === this.order.flights[index].departure.date) {
      this.invalidDate[index] = true;
    } else {
      this.invalidDate[index] = false;
    }

    this.disableSearchButton = this.invalidDate.includes(true);
  }

  multiCityDateValidator() {
    const validateValues = [];
    if (this.originDestinationsData.length === 1) {
      return;
    }
    for (let i = 1; i < this.originDestinationsData.length; i++) {
      const date = this.originDestinationsData[i].departureDate;
      const checkDate = this.originDestinationsData[i - 1].departureDate;
      const dateValue = moment(moment(date, 'YYYY-MM-DD')).unix();
      const checkDateValue = moment(moment(checkDate, 'YYYY-MM-DD')).unix();
      if (dateValue < checkDateValue) {
        this.originDestinationsData[i].isDateInvalid = true;
        validateValues.push(true);
      } else {
        this.originDestinationsData[i].isDateInvalid = false;
        validateValues.push(false);
      }
    }
    this.dataTransferService.itinReshopData$.next([...this.originDestinationsData]);
    const uniqValues = Array.from(new Set(validateValues));
    this.itinReshopModelValid = uniqValues.length > 1 || (uniqValues.length === 1 && uniqValues[0]);
  }

  onSelectedSortStrategy(strategy = null) {
    this.page = 1;
    if (strategy) {
      this.sortStrategy = strategy;
    }

    const tempOD = this.originDestinationsData.map(item => {
      return {
        arrival: {
          airportCode: item.arrival,
          terminalName: '',
          time: ''
        },
        departure: {
          airportCode: item.departure,
          date: item.departureDate,
          terminalName: '',
          time: ''
        }
      };
    });

    this.availableOffersForView = this.helpers.sortOffers(this.sortStrategy, this.availableOffersForView, tempOD);
    this.groupedOffersByFlight = this.helpers.groupOffersByFlights(this.availableOffersForView);
    console.log(this.groupedOffersByFlight);

  }

  private applyFilter() {
    this.filteredOffers = this.availableOffersForViewTable.filter(offer => {
      const fareRulesFilter = this.fareRules ? this.fareRules.split('|') : '';

      return this.offerService.checkDisclosures(offer, this.checkedBaggage, this.fareRules) &&
        this.offerService.checkFareRules(offer, fareRulesToNumberMap, fareRulesFilter);
    });
  }

  private setChangeOrderProcess(isProcess: boolean) {
    this.changeOrderProcess.isProcess = isProcess;
  }

  onTabSelected(nextId: string) {
    this.selectedOrderTypeTab = nextId;
  }

  loadSeatAvailability(seatsModal) {
    this.open(seatsModal);
    if (this.seatAvailability && this.selectedSeats?.length) {
      return;
    }
    this.seatMapService.showSeatAvailabilityLoader = true;
    this.seatMapService.seatAvailabilityError = {};
    this.seatMapService.seats = {};

    let body = {reshopOfferID: this.offerFromItinReshopReprice.offerID};
    this.service.sendSeatAvailability(body)
      .then((response) => {
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.seatAvailabilityResponseWarning = this.helpers.getWarningsFromResponse(response);
        this.seatsSegments = response && response.segments ? response.segments : null;

        let filteredSegments = [];
        this.seatsSegments?.map(seatSegment => {
          this.flightsForRebookingSeats?.map(flight =>
            flight.segments.some(segment => {
              let routeFromAllFlights = seatSegment.originDestination.departure.airportCode + seatSegment.originDestination.arrival.airportCode
              let routeFromRebookingFlights = segment.originDestination.departure.airportCode + segment.originDestination.arrival.airportCode;
              if (routeFromAllFlights === routeFromRebookingFlights) {
                filteredSegments.push(seatSegment);
              }
            })
          );
        });
        this.seatsSegments = filteredSegments;
        this.seatAvailability = response;
        this.seatMapService.prepareSeatAvailability(response, true, this.seatsSegments);
      })
      .catch((response: HttpErrorResponse) => {
        this.seatMapService.showSeatAvailabilityLoader = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, response.status, response.error);
        this.seatMapService.seatAvailabilityError = this.helpers.getError(response);
        if (this.helpers.isCriticalError(response)) {
          throw response;
        }
      });
  }

  open(content, size: any = 'lg', windowClass = '') {
    this.modalService.open(content, {
      size, windowClass
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  isInstaceOfObject(obj) {
    return obj instanceof Object;
  }

  onServicesSelected(services) {
    this.selectedServices = services;
  }

  prepareSegmentSeats() {
    let pairsPipe = new PairsPipe();
    this.selectedSeats = [];

    pairsPipe.transform(this.seatMapService.selectedSeats).map(selectedSeat => {
      let segmentID = selectedSeat[0];
      if (this.isInstaceOfObject(selectedSeat[1])) {
        pairsPipe.transform(selectedSeat[1]).map((seatObject) => {
          if (seatObject[1]) {
            let seat = this.seatMapService.seats[segmentID][seatObject[0]];

            let seatForRequest = {
              location: seat.location,
              segment: seat.segment,
              passenger: seatObject[1],
            };
            this.selectedSeats.push(seatForRequest);
          }
        });
      }
    });
  }

  onSeatSelect() {
    this.prepareSegmentSeats();
  }

  sendOfferPrice() {
    this.getOfferDetailsLoader = true;
    let services = [];

    this.selectedServices.map(serv => {
      serv = this.helpers.updateService(serv);
      let service = {
        action: 'Create',
        quantity: serv.quantity,
        segmentReference: serv.segmentReferences,
        serviceID: serv.serviceID,
        travelerReference: serv.travelerReferences,
        type: 'service'
      };
      services.push(service);
    });

    this.selectedSeats.map(seat => {
      let seatService = {
        type: 'seat',
        seatLocation: seat.location,
        segmentReference: seat.segment,
        travelerReference: seat.passenger,
        action: 'Create',
      };
      services.push(seatService);
    });
    let body = {
      reshopOfferID: this.offerFromItinReshopReprice.offerID,
      services
    }
    this.service.sendOfferPrice(body)
      .then((response: any) => {
        this.getOfferDetailsLoader = false;
        this.totalOfferPrice = response.price?.consumer?.total;
        this.offerPriceCurrency = response.price?.consumer?.currency;
      })
      .catch((response: HttpErrorResponse) => {
        this.getOfferDetailsLoader = false;
        this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, response.status, response);
        this.offerPriceError = this.helpers.getError(response);
        this.tabSelect('details');
        if (this.helpers.isCriticalError(response)) {
          throw response;
        }
      });
  }

  private get hasServiceList(): boolean {
    return !!this.offerFromItinReshopReprice.allowedRequests?.ServiceList;
  }

  private get hasSeatAvailability(): boolean {
    return !!this.offerFromItinReshopReprice.allowedRequests?.SeatAvailability;
  }

  private get noSelectedServices(): boolean {
    return !this.selectedServices.length;
  }

  private get noSelectedSeats(): boolean {
    return !this.selectedSeats.length;
  }

  private get isReshopNotAllowed(): boolean {
    return (this.hasServiceList && this.hasSeatAvailability && this.noSelectedServices && this.noSelectedSeats)
              || (this.hasServiceList && this.noSelectedServices)
                || (this.hasSeatAvailability && this.noSelectedSeats);
  }

  private get reshopPopoverMessage(): string | undefined {
    if (this.hasServiceList && this.hasSeatAvailability && this.noSelectedServices && this.noSelectedSeats) {
      return 'Seat and service selection required to continue';
    } else if (this.hasServiceList && this.noSelectedServices) {
      return 'Service selection required to continue';
    } else if (this.hasSeatAvailability && this.noSelectedSeats) {
      return 'Seat selection required to continue';
    }
    return;
  }

  createMinAndMaxDates() {
    this.originDestinationsData.forEach((od, index) => {
      if (this.originDestinationsData[index + 1]) {
        this.setMinDate(index + 1, od.departureDate);
        this.setMaxDate(index, this.originDestinationsData[index + 1].departureDate);
      }
    });
  }

  updateMinAndMaxDates(destinationData: any, index: number) {
    if (this.originDestinationsData[index + 1]) {
      this.setMinDate(index + 1, destinationData.departureDate);
    } else if (this.originDestinationsData[index - 1]) {
      this.setMaxDate(index - 1, destinationData.departureDate);
    }
  }

  private setMinDate(index: number, date: string) {
    const ngbDate = this.helpers.formatDateToNgbDateStruct(date);
    this.minDates[`departure_date[${index}]`] = HelpersService.isFirstDateGreater(this.currentDate, ngbDate)
                                                ? this.currentDate : ngbDate;
  }

  private setMaxDate(index: number, date: string) {
    const ngbDate = this.helpers.formatDateToNgbDateStruct(date);
    if (!HelpersService.isFirstDateGreater(this.currentDate, ngbDate)) {
      this.maxDates[`departure_date[${index}]`] = ngbDate;
    }
  }
}
